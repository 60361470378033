













































































































import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";
import { formatRangeDates } from "@/utils/index";
import { MessageBox, Notification } from "element-ui";
import { IAgreement } from "@/store/agreement/interfaces";
import AgreementStore from '@/store/agreement/index';
import CreateAgreementModal from '@/views/agreement/modals/CreateAgreementModal.vue';
import moment from "moment";

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
});

const defaultSorts = JSON.stringify(
  'createdAt-desc'
);

@Component({
components: { CreateAgreementModal },
})
export default class Agreement extends Mixins<TranslateMixin>(
TranslateMixin
) {
  loading = false;
  loadingPdfBtn = false;
  isAgreementFile = false
  showFilters = false;

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  additionalColumns = {
    innerOperationRequired: false
  }

  $refs!: {
    CreateAgreementModal: CreateAgreementModal;
  };

  selectedAgreement = {} as IAgreement

  mounted() {
    this.init();
  }

  async init() {
    this.loadingPdfBtn = false;
    this.getAgreementList();
  }

  async getAgreementList() {
    this.loading = true;
    await AgreementStore.getAgreementList(this.filters);
    this.loading = false;
    this.closeFilters();
  }

  async getAgreementFile(agreement: IAgreement) {
    this.selectedAgreement = agreement;
    this.loadingPdfBtn = true;
    this.isAgreementFile = false
    const agreementFile = await AgreementStore.getAgreementFile(agreement.id);
    this.isAgreementFile = agreementFile ? true : false
    this.loadingPdfBtn = false;
  }

  async downloadAgreementFile(agreement: IAgreement) {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf,${encodeURI(this.agreementFile as any)}`;
    downloadLink.download = `Agreement_${agreement.number}.pdf`;
    downloadLink.click();
  }

  async openAgreementFile(agreement: IAgreement) {
    window.open(window.location.href + '/open/' + agreement.id, '_blank');
  }

  async handleActions(event: any) {
    this.selectedAgreement = event.row;

      if (event.name === "deleteAgreement") {
        MessageBox.confirm(
          `Вы действительно хотите расторгнуть договор организации ${ this.translate('organization', this.selectedAgreement.orgId) } от ${ moment(this.selectedAgreement.createdAt).format("DD.MM.YYYY") } ?`,
          "Расторжение договора",
          {
            confirmButtonText: "Расторгнуть",
            cancelButtonText: "Отмена",
            center: true,
            confirmButtonClass: "el-button--danger",
            type: "error",
          }
        ).then(async () => {
          this.loading =  true
          await AgreementStore.deleteAgreement(this.selectedAgreement.id)
          await this.init()
          this.loading =  false
        })
      }

      if (event.name === "copyСode") {
        navigator.clipboard.writeText(this.selectedAgreement.id)
        Notification.success({
          title: "Успешно",
          message: "Код синхронизации скопирован",
        })
      }
  }

  showCreateAgreement() {
    this.$refs.CreateAgreementModal.open();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getAgreementList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getAgreementList();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getAgreementList();
  }

  get agreementList() {
    return AgreementStore.agreementList;
  }

  get agreementFile() {
    return AgreementStore.agreementFile;
  }

  changeDate(dates: string[]) {
    this.filters.dates = formatRangeDates(dates);
  }
}
