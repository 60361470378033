import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPaginationParams, ISortSearch } from "@/interfaces";
import { ESubjectType, IBillingEventsList } from "./interfaces";

@Module({ dynamic: true, store, name: "BillingEvents" })
class BillingEventsStore extends VuexModule {
  public _billingEventsList: IBillingEventsList =
    {} as IBillingEventsList;

  @Mutation
  public SET_BILLING_EVENTS_LIST(data: IBillingEventsList): void {
    this._billingEventsList = data;
  }

  @Action({ rawError: true })
  public async getBillingEventsList(filters?: 
    IPaginationParams & 
    ISortSearch & 
    { subjectType?: ESubjectType[] }
    ) {
    const response: AxiosResponse<IBillingEventsList> =
      await instanceBilling.get("/systemEvents", { params: filters });

    this.SET_BILLING_EVENTS_LIST(response.data);

    return response.data;
  }

  get billingEventsList() {
    return this._billingEventsList;
  }
}

export default getModule(BillingEventsStore);
