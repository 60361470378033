







import { IOrganization } from "@/store/organization/interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import OrganizationStore from "@/store/organization";

@Component({})
export default class SelectOrganizations extends Vue {
  @Prop({ default: '' }) value!: any;

  filters = {
    page: 0,
    limit: 30,
    sort: {
            name: "ASC"
          }
  }

  orgList = [] as IOrganization[]

  async inputFocus() {
    if(this.orgList?.length != this.organizationList?.total) {
      this.filters = {
        page: this.filters.page + 1,
        limit: this.filters.limit,
        sort: this.filters.sort
      }
      await this.getOrgList()
      this.orgList.push(...this.organizationList?.data) 
    }

    this.setLoadingObserver()
  }

  async getOrgList() {
    await OrganizationStore.getOrganizations_(this.filters)
  }

  get organizationList() {
    return OrganizationStore.organizations_?.result;
  }

  async showMore() {
    this.filters = {
      page: this.filters.page + 1,
      limit: this.filters.limit,
      sort: this.filters.sort
    }
    await this.getOrgList()
    this.orgList.push(...this.organizationList?.data) 
  }

  setLoadingObserver() {
    const elementToObserve = document.querySelector('#load');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => { 
        if (entry.isIntersecting) this.showMore()
      })
    });

    observer.observe(elementToObserve!);
  }
}
