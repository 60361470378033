
































import { Component, Mixins } from "vue-mixin-decorator";
import FiscalMemoryStore from "@/store/fiscalMemory";
import TranslateMixin from "@/mixins/TranslateMixin";
import { ICashBox } from "@/store/cashbox/interfaces";
import { Prop } from "vue-property-decorator";
import { Notification } from "element-ui";

@Component({
  mixins: [TranslateMixin],
})
export default class FiscalMemoryModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  @Prop({ default: false }) readonly nested!: boolean;
  @Prop() readonly cashboxId!: number;

  selecedCashBox = {} as ICashBox;

  show = false;

  load = false;

  form = {
    number: null,
    expinTime: '',
    activedTime: '',
  };

  open() {
    this.show = true;
  }

  handleClose() {
    return;
  }

  async handleOpen() {
    return;
  }

  async handle() {
    await FiscalMemoryStore.createFiscalMemoryCashbox({
      cashboxId: this.cashboxId,
      form: this.form,
    });
    this.$emit("submit");
    this.show = false;
  }

  changeActivedTime() {
    if(this.form.activedTime && this.form.expinTime
      && new Date(this.form.activedTime).getTime() > new Date(this.form.expinTime).getTime()) {
        this.form.activedTime = ''
        Notification.error({
          title: "Ошибка",
          message: "Дата активации не может быть позднее даты окончания",
        });
      }
  }

  changeExpinTime() {
    if(this.form.activedTime && this.form.expinTime
      && new Date(this.form.expinTime).getTime() < new Date(this.form.activedTime).getTime()) {
        this.form.expinTime = ''
        Notification.error({
          title: "Ошибка",
          message: "Дата окончания не может быть раньше даты активации",
        });
      }
  }
}
