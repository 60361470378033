
















import { Component, Emit, Vue } from "vue-property-decorator";
import AuthStore from "@/store/auth";
import RootStore from "@/store/root";

@Component({
  props: {
    collapse: Boolean,
  },
})
export default class Menu extends Vue {
  @Emit()
  collapseSidebar(): void {
    console.log("emit");
  }

  get isAuth() {
    return AuthStore.loggedIn;
  }

  async login() {
    await AuthStore.login();
  }

  async logout() {
    await AuthStore.logout();
  }

  get roleTranslate() {
    const roles = Object.keys(AuthStore.userInfo).length
      ? AuthStore.userInfo.roles
      : [];

    const cRole = roles[0];

    return cRole &&
      RootStore.roleTranslates[cRole] &&
      RootStore.roleTranslates[cRole].name
      ? RootStore.roleTranslates[cRole].name
      : "Неизвестно";
  }

  get userName(): string {
    const user = Object.keys(AuthStore.userInfo).length
      ? AuthStore.userInfo.user
      : null;
    return user ? `${user.lastName} ${user.firstName}` : "Неизвестно";
  }
}
