import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";
import {
  IBidResponse,
  IBidsResponse,
  IBid,
  IBidsResponseResult,
  IBidStatus
} from "@/store/bid/interface";
import { AxiosResponse } from "axios";

@Module({ dynamic: true, store, name: "Bids" })
class Bid extends VuexModule {
  private _bids: IBidsResponse = {} as IBidsResponse;
  private _bid: IBidResponse = {} as IBidResponse;

  @Mutation
  public SET_BIDS(data: IBidsResponse): void {
    this._bids = data;
  }

  @Mutation
  public SET_BID(data: IBidResponse): void {
    this._bid = data;
  }

  @Action({ rawError: true })
  public async getBids(filters?: {
    limit?: number;
    page?: number;
    sort?: any;
    filter?: any;
    range?: any;
  }) {
    const response: AxiosResponse<IBidsResponse> = await instanceBackend.post(
      "/bid/list",
      filters
    );

    this.SET_BIDS(response.data);
  }

  @Action({ rawError: true })
  public async updateBid(requestBody: { bid: any; id: number }) {
    await instanceBackend.put(`/bid/${requestBody.id}`, requestBody.bid);
  }

  @Action({ rawError: true })
  public async getBid(id: number) {
    const response: AxiosResponse<IBidResponse> = await instanceBackend.get(
      `/bid/${id}`
    );

    this.SET_BID(response.data);
  }

  get bids(): IBidsResponseResult {
    return this._bids && this._bids.result
      ? this._bids.result
      : ({} as IBidsResponseResult);
  }

  get bid(): IBid {
    return this._bid && this._bid.result ? this._bid.result : ({} as IBid);
  }

  get bidStatuses(): IBidStatus[] {
    return [
      {
        id: 11,
        name: "Новая",
        type: "primary",
      },
      {
        id: 10,
        name: "Принята",
        type: "warning",
      },
      {
        id: 9,
        name: "Ожидает оплаты",
        type: "info",
      },
      {
        id: 8,
        name: "Обрабатывается",
        type: "warning",
      },
      {
        id: 1,
        name: "Отказана",
        type: "danger",
      },
      {
        id: 0,
        name: "Исполнена",
        type: "success",
      },
    ];
  }

  get bidStatusesMap() {
    return this.bidStatuses.reduce((acc, status) => {
      if (!acc[status.id]) acc[status.id] = status;

      return acc;
    }, {} as { [key: string]: { id: number; name: string } });
  }
}

export default getModule(Bid);
