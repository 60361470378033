



























































































import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";
import LicenseStore from "@/store/licenses";
import router from "@/router";
import { MessageBox } from "element-ui";
import TariffStore from "@/store/tariff/index";
import TransportStore from "@/store/transport/index";

const defaultFormList = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [] as string[],
  accountId: [] as number[],
  status: [] as string[],
  service: [] as string[],
});

@Component({
  mixins: [TranslateMixin],
  props: {
    params: { type: Object },
  },
})
export default class EditLicense extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  show = false;
  load = false;
  formList = JSON.parse(defaultFormList);
  activeNames = ''

  mounted() {
    if(this.$route.params.id) {
      this.show = true;
      this.init()
    }
  }

  async init() {
    this.formList = {...this.formList, ...this.$route.query }
    this.load = true;
    await LicenseStore.getLicense(this.$route.params.id);
    await TariffStore.getTariffsList({productId: this.license.tariff.productId});
    this.load = false;
  }

  async updateLicenseName() {
    await LicenseStore.updateLicense({
      licenseId: this.$route.params.id,
      form: {
        name: this.license.name
      },
    });
    this.init()
  }

  async updateLicenseTariffId() {
    await LicenseStore.updateLicenseSync({
      licenseId: this.$route.params.id,
      form: {
        tariffId: this.license.tariffId
      },
    });
    this.init()
  }

  async updateLicenseSync() {
    await LicenseStore.updateLicenseSync({
      licenseId: this.$route.params.id,
      form: {
        activeTo: this.license.activeTo,
        status: this.license.status
      },
    });
    this.init()
  }

  handleOpen() {
    //
  }

  async pauseLicense() { //приостановить
    MessageBox.confirm(
      `Вы действительно хотите приостановить лицензию "${this.license.name}"?`,
      "Приостановление лицензии",
      {
        confirmButtonText: "Приостановить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--warning",
        type: "warning",
      }
    ).then(async () => {
      await LicenseStore.pauseLicense(this.license.licenseId)
      this.init()
    })
  }

  async prolongateLicense() { // продлить
    MessageBox.confirm(
      `Вы действительно хотите продлить лицензию "${this.license.name}"?`,
      "Продление лицензии",
      {
        confirmButtonText: "Продлить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--success",
        type: "success",
      }
    ).then(async () => {
      await LicenseStore.prolongateLicense(this.license.licenseId) 
      this.init()
    })
  }

  async deactivateLicense() { //деактивировать
    MessageBox.confirm(
      `Вы действительно хотите деактивировать лицензию "${this.license.name}"?
      После совершения данного действия лицензия не подлежит восстановлению.`,
      "Деактивация лицензии",
      {
        confirmButtonText: "Деактивировать",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--danger",
        type: "error",
      }
    ).then(async () => {
      await LicenseStore.deactivateLicense(this.license.licenseId) 
      this.init()
    })
  }

  async unbindDevice() {
    this.$confirm(
      `Вы действительно хотите отвязать устройство от "${this.license.name}"?`,
      "Осторожно",
      {
        confirmButtonText: "Отвязать",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--danger",
        type: "error",
      }
    ).then(async () => {
      await TransportStore.unbindDevice(this.$route.params.id);
      this.init();
    });
  }

  async handleClose() {
    await LicenseStore.getLicenseList({
      ...this.formList,
    });
    this.show = false;
    router.push('/licenses')
  }

  get license() {
    return LicenseStore.license;
  }

  get tariffs() {
    return TariffStore.tariffsList.data;
  }
}
