import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import {
  ILicenseStatus,
  ILicense,
  ELicenseStatus,
  ILicenseListResponse,
} from "@/store/licenses/interfaces";
import { IPaginationParams, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "License" })
class LicenseStore extends VuexModule {
  public _licenseLicenseList: ILicenseListResponse =
    {} as ILicenseListResponse;
  public _licenseLicenseListStatistic: ILicenseListResponse =
    {} as ILicenseListResponse;
  public _licenseLicense: ILicense = {} as ILicense;

  @Mutation
  public SET_LICENSE_LIST(data: ILicenseListResponse): void {
    this._licenseLicenseList = data;
  }

  @Mutation
  public SET_LICENSE_LIST_STATISTIC(data: ILicenseListResponse): void {
    this._licenseLicenseListStatistic = data;
  }

  @Mutation
  public SET_LICENSE(data: ILicense): void {
    this._licenseLicense = data;
  }

  @Action({ rawError: true })
  public async getLicenseList(filters?: 
    IPaginationParams & 
    ISortSearch & 
    {
      dates?: string[];
      accountId?: number[];
      status?: ELicenseStatus[],
      service?: string[]
  }) {
    const response: AxiosResponse<ILicenseListResponse> =
      await instanceBilling.get("/licenses", { params: filters });

    this.SET_LICENSE_LIST(response.data);

    return response.data;
  }

  @Action({ rawError: true })
  public async getLicenseListForStatistic(filters?: 
    IPaginationParams & 
    ISortSearch & 
    {
      dates?: string[];
      accountId?: number[];
      status?: ELicenseStatus[],
      service?: string[]
  }) {
    const response: AxiosResponse<ILicenseListResponse> =
      await instanceBilling.get("/licenses", { params: filters });

    this.SET_LICENSE_LIST_STATISTIC(response.data);

    return response.data;
  }

  @Action({ rawError: true })
  public async getLicense(licenseId: string) {
    const response: AxiosResponse<ILicense> =
      await instanceBilling.get(`/licenses/${licenseId}`);

    this.SET_LICENSE(response.data);

    return response.data;
  }

  @Action({ rawError: true })
  public async updateLicense(options: {
    licenseId: string;
    form: {
      name: string
    };
  }) {
    const response: AxiosResponse<ILicense> =
      await instanceBilling.patch(
        `/licenses/${options.licenseId}`,
        options.form
      );

    return response;
  }

  @Action({ rawError: true })
  public async updateLicenseSync(options: {
    licenseId: string;
    form: {
      activeTo?: string,
      status?: ELicenseStatus,
      tariffId?: string
    };
  }) {
    const response: AxiosResponse<ILicense> =
      await instanceBilling.patch(
        `/licenses/${options.licenseId}/sync`,
        options.form
      );

    return response;
  }

  @Action({ rawError: true })
  public async prolongateLicense(licenseId: string) {
    const response = await instanceBilling.post(`/licenses/${licenseId}/prolongate`);

    return response
  }

  @Action({ rawError: true })
  public async deactivateLicense(licenseId: string) {
    const response = await instanceBilling.post(`/licenses/${licenseId}/deactivate`);

    return response
  }

  @Action({ rawError: true })
  public async pauseLicense(licenseId: string) {
    const response = await instanceBilling.post(`/licenses/${licenseId}/pause`);

    return response
  }

  @Action({ rawError: true })
  public async getBalanceList() {
    const response: any = await instanceBilling.get(`/balance/all`);

    return response?.data
  }

  get licenseList() {
    return this._licenseLicenseList;
  }

  get licenseListStatistic() {
    return this._licenseLicenseListStatistic;
  }

  get license() {
    return this._licenseLicense;
  }

  get licenseStatuses(): ILicenseStatus[] {
    return [
      {
        id: ELicenseStatus.ACTIVE,
        name: "Активна",
        type: 'success'
      },
      {
        id: ELicenseStatus.INACTIVE,
        name: "Неактивна",
        type: 'info'
      },
      {
        id: ELicenseStatus.PAUSED,
        name: "Приостановлена",
        type: 'warning'
      },
      {
        id: ELicenseStatus.EXPIRED,
        name: "Истекла",
        type: 'danger'
      },
    ];
  }
}

export default getModule(LicenseStore);
