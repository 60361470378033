import Vue from "vue";
import config from "@/config";

export class TokenStorage {
  public static isAuthenticated(): boolean {
    return !!TokenStorage.getAccessToken();
  }

  public static clear(): void {
    Vue.cookies.remove(config.tokens.access);
  }

  public static getAccessToken(): any {
    return Vue.cookies.get(config.tokens.access);
  }

  public static setAccessToken(token: string): void {
    Vue.cookies.set(config.tokens.access, token);
  }
}
