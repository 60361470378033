import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";

@Module({ dynamic: true, store, name: "FiscalMemory" })
class FiscalMemory extends VuexModule {
  @Action({ rawError: true })
  public async createFiscalMemoryCashbox(options: {
    cashboxId: number;
    form: {
      number: string | null;
      expinTime: string | null;
      activedTime: string | null;
    };
  }) {
    await instanceBackend.post(
      `/cashbox/${options.cashboxId}/addFn`,
      options.form
    );
  }
}

export default getModule(FiscalMemory);
