import AuthStore from "@/store/auth";

function access(rights: string[]) {
  if (!rights || !rights.length) return true;

  const myRights = AuthStore.userInfo.rights;
  const myRoles = AuthStore.userInfo.roles;

  if (myRoles.includes("SUPER_ADMIN")) return true;

  let check = rights.some((right) => myRights.includes(right));

  if (!check) {
    check = rights.some((right) => myRoles.includes(right));
  }

  return check;
}

const accessPlugin = {
  install(Vue: any) {
    Vue.prototype.$access = access;
    Vue.$access = access;
  },
};
export default accessPlugin;
