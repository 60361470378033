import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceAms, instanceBackend, instanceBilling } from "@/plugins/axios";
import {
  IDictionaries,
  IDictionariesResponse,
  IRoleTranslates,
  IServicesResponse,
} from "@/store/root/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import AuthStore from "@/store/auth";
import AccountStore from "@/store/account";
import { Notification } from "element-ui";
import BidStore from "@/store/bid";
import QrStore from "@/store/qr";
import TransportStore from "@/store/transport";
import CashboxStore from "@/store/cashbox";
import AccountMembersStore from "@/store/accontMembers";
import LicenseStore from "@/store/licenses";
import OrganizationStore from "@/store/organization";
import WorkerStore from "@/store/worker";
import AgreementStore from '@/store/agreement';

@Module({ dynamic: true, store, name: "Root" })
class Root extends VuexModule {
  private _roleTranslates = {} as IRoleTranslates;
  private _dictionaries = {} as IDictionaries;
  private _initState = false;
  private _access = true;
  private _allowedRoles = ["SUPER_ADMIN", "ADMIN"];

  @Mutation
  public SET_ROLE_TRANSLATES(data: IRoleTranslates): void {
    this._roleTranslates = data;
  }

  @Mutation
  public SET_DICTIONARIES(params: {
    field: keyof IDictionaries;
    keyId?: string;
    data: IDictionaries[keyof IDictionaries]["list"];
  }): void {
    if (!params.keyId) params.keyId = "id";

    if (!this._dictionaries[params.field])
      this._dictionaries[params.field] = { list: [], map: {} };

    this._dictionaries[params.field].list = params.data;

    const map = {} as any;

    for (let index = 0; index < params.data?.length; index++) {
      const element = params.data[index] as any;

      if (!map[element[params.keyId]]) map[element[params.keyId]] = element;
    }

    this._dictionaries[params.field].map = map;
  }

  @Mutation
  public SET_INIT_STATE(value: boolean): void {
    this._initState = value;
  }

  @Mutation
  public SET_ACCESS(value: boolean): void {
    this._access = value;
  }

  @Action({ rawError: true })
  public async fetchDictionaries() {
    const responseBackend: AxiosResponse<IDictionariesResponse> =
      await instanceBackend.get("/info/dictionaries");

    const responseBilling: AxiosResponse<IDictionariesResponse> =
      await instanceBilling.get("/dictionary");

    if(responseBackend.data && responseBackend.data.result) {
      const response = {
      data: {
        result: Object.assign(responseBackend.data.result, responseBilling.data)
          }
        }

        const listDictionaryEng = ['feePeriodTypesMapped', 'serviceNamesMapped', 'subjectTypes']  
        
        if (response.data && response.data.result) {
          Object.entries(response.data.result).forEach(([key, value]) => {
            return this.SET_DICTIONARIES({
              data: value,
              keyId: listDictionaryEng.includes(key) ? "eng" : "id",
              field: key as keyof IDictionariesResponse["result"],
            });
          });
        } 

        this.SET_DICTIONARIES({ data: responseBackend.data.result.cashbox, field: "cashboxOrganizationId" });
    }
  }

  @Action({ rawError: true })
  public async fetchRoleTranslates() {
    const response: AxiosResponse<IRoleTranslates> = await instanceAms.get(
      "/roles/translates"
    );

    if (response && response.data) {
      this.SET_ROLE_TRANSLATES(response.data);
    }
  }

  @Action({ rawError: true })
  public async fetchServices() {
    const response: AxiosResponse<IServicesResponse> = await instanceAms.get(
      "/services"
    );

    if (response && response.data) {
      this.SET_DICTIONARIES({ data: response.data.data, field: "services" });
    }
  }

  @Action({ rawError: true })
  public async initApp() {
    let access = true;

    await AuthStore.login();

    const myRoles = AuthStore.userInfo.roles;
    const myRights = AuthStore.userInfo.rights;

    const check =  
      this._allowedRoles.some((role) => myRoles.includes(role)) || 
      myRoles.some((role) => role.indexOf('SUPPORT') === 0);


    if (!check) {
      Notification.error({
        title: "Доступ запрещен",
        message: "У Вас нет прав на использование админ панели",
      });

      access = false;
    }

    this.SET_ACCESS(access);

    if(myRights.includes("ACCOUNT_LIST")){
      await AccountStore.getAccountList()
    }

    if(myRights.includes("SERVICES_LIST")){
      await this.fetchServices()
    }

    if(myRights.includes("OWNERS_LIST")){
      await AccountMembersStore.getOwnersList()
    }

    await Promise.all([
      this.fetchRoleTranslates(),
      this.fetchDictionaries(),
      this.fetchServices(),
      AccountStore.getAccountList(),
      WorkerStore.getWorkers(),
    ]);


    this.SET_DICTIONARIES({ 
      data: WorkerStore.workerList, 
      field: "workers" 
    });

    this.SET_DICTIONARIES({ 
      data: BidStore.bidStatuses, 
      field: "bidStatuses" 
    });

    this.SET_DICTIONARIES({
      data: QrStore.qrLicenseStatuses,
      field: "qrLicenceStatuses",
    });

    this.SET_DICTIONARIES({
      data: QrStore.qrRegisterStatuses,
      field: "qrRegisterStatuses",
    });

    this.SET_DICTIONARIES({
      data: TransportStore.transportLicenseStatuses,
      field: "transportLicenceStatuses",
    });

    this.SET_DICTIONARIES({
      data: TransportStore.transportRegisterStatuses,
      field: "transportRegisterStatuses",
    });

    this.SET_DICTIONARIES({
      data: CashboxStore.cashboxStatuses,
      field: "cashboxStatuses",
    });

    this.SET_DICTIONARIES({
      data: LicenseStore.licenseStatuses,
      field: "licenceStatuses",
    });

    this.SET_DICTIONARIES({
      data: AgreementStore.agreementStatuses,
      keyId: "eng",
      field: "agreementStatuses",
    });

    this.SET_INIT_STATE(true);
  }

  get roleTranslates(): IRoleTranslates {
    return {
      SUPER_ADMIN: { key: "SUPER_ADMIN", name: "Главный администратор" },
      SUPPORT: { key: "SUPPORT", name: "Техническая поддержка" },
      ADMIN: { key: "ADMIN", name: "Администратор" },
      ...this._roleTranslates,
    };
  }

  get initState() {
    return this._initState;
  }

  get access() {
    return this._access;
  }

  get dictionaries() {
    return this._dictionaries;
  }
}

export default getModule(Root);
