









































































import { Component, Vue, Watch } from "vue-property-decorator";
import RolesStore from "@/store/roles";
import { IRight, IRole, IRoleDependency } from "@/store/roles/interfaces";
import { updateQuery } from "@/utils";
import RolesModal from "@/views/roleSettings/modals/RolesModal.vue";
import RoleRightsModal from "@/views/roleSettings/modals/RoleRightsModal.vue";

@Component({
  props: {
    serviceId: { type: Number, required: true },
  },
  components: { RolesModal, RoleRightsModal },
})
export default class Roles extends Vue {
  $refs!: {
    RolesModal: RolesModal;
    RoleRightsModal: RoleRightsModal;
  };
  roles: IRole[] = [];
  rights: IRight[] = [];
  roleDependency: IRoleDependency[] = [];
  loading = false;
  filters = {
    search: this.$route.query.search || "",
  };
  editableRow = null;
  selectedRole: IRole | null = null;
  selectedType = null;

  mounted() {
    this.fetchData();
  }

  openModal(name: "RolesModal" | "RoleRightsModal") {
    const modal = this.$refs[name];

    modal.open();
  }

  @Watch("serviceId")
  serviceChange() {
    this.fetchData();
  }

  async changeSearch() {
    updateQuery(this, this.filters);
    if (this.selectedRole) {
      this.fetchRoleRights();
    } else {
      this.fetchData();
    }
  }

  async updateRole() {
    await RolesStore.updateRole(this.editableRow as any);
    this.editableRow = null;
    this.fetchData();
  }

  async fetchData() {
    this.selectedRole = null;
    this.selectedType = null;
    this.loading = true;
    this.roles = await RolesStore.fetchServiceRoles({
      serviceId: this.$props.serviceId,
      filters: this.filters,
    });
    this.loading = false;
  }

  async fetchRoleRights() {
    if (!this.selectedRole) return;
    this.loading = true;

    this.rights = await RolesStore.fetchRoleRights({
      role: this.selectedRole,
      filters: this.filters,
    });

    this.loading = false;
  }

  async fetchRoleDependency() {
    if (!this.selectedRole) return;

    this.loading = true;

    const data = await RolesStore.fetchRoleDependency({
      role: this.selectedRole,
      filters: this.filters,
    });

    this.roleDependency = data.result;

    this.loading = false;
  }

  viewRoleRightsList(row: any) {
    console.log(row);
  }

  async handleActions(event: any) {
    if (event.name === "RemoveRole") {
      this.$confirm(
        `Если удалить роль без обратной совместимости, то все роуты завязанные на этой роли перестанут работать. Удалить роль ${event.row.key}?`,
        "Осторожно",
        {
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await RolesStore.removeRole({
          serviceId: this.$props.serviceId,
          key: event.row.key,
        });
        this.fetchData();
      });
    }
    if (event.name === "RoleRights") {
      this.selectedType = event.name;
      this.selectedRole = event.row;

      this.fetchRoleRights();
    }
    if (event.name === "RoleDependency") {
      this.selectedType = event.name;
      this.selectedRole = event.row;

      this.fetchRoleDependency();
    }
    if (event.name === "EditRole") {
      this.editableRow = event.row;
    }
  }
}
