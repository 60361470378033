import { Vue } from "vue-property-decorator";
import { Mixin } from "vue-mixin-decorator";
import { IDictionaries } from "@/store/root/interfaces";

type TranslateType = keyof IDictionaries | "connectInterfaceOs";

const typesMap: { [key in TranslateType]: string } = {
  organization: "name",
  organizationSort: "name",
  cashboxModel: "model",
  connectInterface: "interface",
  services: "name",
  accounts: "name",
  bidStatuses: "name",
  connectInterfaceOs: "os",
  ffd: "name",
  fnType: "name",
  ofd: "name",
  sno: "name",
  agentTypes: "name",
  qrLicenceStatuses: "name",
  qrRegisterStatuses: "name",
  transportLicenceStatuses: "name",
  licenceStatuses: "name",
  transportRegisterStatuses: "name",
  cashboxStatuses: "name",
  cashbox: "regNumber",
  cashboxOrganizationId: "organizationId",
  owners: "fio",
  feePeriodTypesMapped: "name",
  serviceNamesMapped: "name",
  subjectTypes: "name",
  workers: "name",
  agreementStatuses: "name",
};

@Mixin
export default class TranslateMixin extends Vue {
  public translate(
    key: TranslateType,
    id: string | number,
    field?: string
  ): string {
    const notFoundString = "Неизвестно";

    const modifyKey = key === "connectInterfaceOs" ? "connectInterface" : key;

    if (!typesMap[modifyKey]) return notFoundString;

    const info: { [key in string | number]: any } = this.$dictionary({
      key: modifyKey,
      type: "map",
    });

    return (
      (info && info[id as any] && info[id][field ? field : typesMap[key]]) || id
    );
  }

  getAccountOwner(accountId: number) {
    const account = this.$dictionary({ key: "accounts", type: "map" })[
      accountId
    ];

    return account &&
      account.members &&
      Array.isArray(account.members) &&
      account.members.length
      ? account.members[0].user.fio
      : "Нет данных";
  }

  getMobileAccountOwner(accountId: number) {
    const account = this.$dictionary({ key: "accounts", type: "map" })[
      accountId
    ];

    return account &&
      account.members &&
      Array.isArray(account.members) &&
      account.members.length
      ? account.members[0].user.mobile
      : "Нет данных";
  }
}
