export interface IAgreement {
  id: string,
  accountId: number,
  orgId: number,
  status: string,
  activeFrom: string,
  activeTo: string,
  number: number,
  createdAt: string,
  updatedAt: string, 
}

export interface IAgreementFile {
  data: string
}

export enum EAgreementStatus {
  NEW = "NEW",
  WAITING = "WAITING",
  DONE = "DONE",
  INACTIVE = "INACTIVE"
}

export interface IAgreementStatus {
  eng: EAgreementStatus;
  name: string;
  type: string;
}