























































































import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";

@Component({
  mixins: [TranslateMixin],
  props: {
    license: Object,
  },
})
export default class ReadPayboxLicense extends Mixins<TranslateMixin>(
  TranslateMixin
) { }
