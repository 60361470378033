





















import { Component, Vue } from "vue-property-decorator";
import ProductStore from "@/store/product/index";

const initForm = JSON.stringify({
    name: "",
    serviceName: ""
});

@Component({})

export default class CreateProductModal extends Vue {
  show = false

  form = JSON.parse(initForm)

  open() {
    this.show = true;
  }

  handleClose() {
    this.show = false;
  }

  async handleOpen() {
    return;
  }

  refreshForm() {
    this.form = JSON.parse(initForm)
  }

  async submit() {
    await ProductStore.createProduct(this.form);
    this.refreshForm();
    this.show = false;
    this.$emit("reload");
  }
}
