import {
    VuexModule,
    Module,
    Mutation,
    Action,
    getModule,
  } from "vuex-module-decorators";
import { instanceBilling } from "@/plugins/axios";
import store from "@/store";
import {
  IProduct,
  IProductList
} from "@/store/product/interfaces";
import { AxiosResponse } from "axios";
import { IPagination } from "@/interfaces";
  
  @Module({ dynamic: true, store, name: "Products" })
  class Product extends VuexModule {
    public _productsList = {} as IProductList;
    public _product: IProduct = {} as IProduct;
  
    @Mutation
    public SET_PRODUCT_LIST(data: IProductList): void {
      this._productsList = data;
    }

    @Mutation
    public SET_PRODUCT(data: IProduct): void {
      this._product = data;
    }
  
    @Action({ rawError: true })
    public async getProductsList(filters: IPagination) {
      const response: AxiosResponse<IProductList> =
        await instanceBilling.get("/products", { params: filters });

      this.SET_PRODUCT_LIST(response.data);
      
      return response.data;
    }

    @Action({ rawError: true })
    public async getProduct(id: string) {
      const response: AxiosResponse<IProduct> = await instanceBilling.get(
        `/products/${id}`
      );
  
      this.SET_PRODUCT(response.data);
  
      return response;
    }

    @Action({ rawError: true })
    public async createProduct(body: {
      name: string,
      serviceName: string
    }) {
      await instanceBilling.post("/products", body);
    }

    @Action({ rawError: true })
    public async removeProduct(id: string) {
      await instanceBilling.delete(`/products/${id}`);
    }

    @Action({ rawError: true })
    public async updateProduct(body: {
      id: string,
      name: string,
      serviceName: string
    }) {
      await instanceBilling.put(`/products/${body.id}`, body);
    }
  
    get productsList() {
      return this._productsList;
    }

    get product() {
      return this._product;
    }

  }
  
  export default getModule(Product);