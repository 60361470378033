




















































































import { Component, Vue } from "vue-property-decorator";
import RolesStore from "@/store/roles";
import { IRight, IRole } from "@/store/roles/interfaces";
import { IService } from "@/store/root/interfaces";

@Component({
  props: {
    right: { type: Object },
    serviceId: { type: Number },
  },
})
export default class RightsModal extends Vue {
  show = false;
  loading = false;
  showRoleForm = false;
  showExportRightForm = false;
  rolesList: IRole[] | null = null;
  exportedRights: IRight[] = [];
  exportRightForm = {
    serviceId: null,
    rights: [] as string[],
  };
  roleForm = {
    key: "",
    name: "",
    description: "",
  };
  form = {
    serviceId: 1,
    rights: [
      {
        id: Math.random(),
        key: "",
        name: "",
        description: "",
        roles: [],
      },
    ],
  };

  async batchCreateRole() {
    await RolesStore.batchCreateRights(this.form);
  }

  async createRole() {
    await RolesStore.createRole({
      serviceId: this.form.serviceId,
      ...this.roleForm,
    });

    this.fetchServiceRoles();
  }

  exportRights() {
    this.exportRightForm.rights.map((r) => {
      console.log("r", r);
      const foundRight = this.exportedRights.find((exportRight) => {
        return exportRight.key === r;
      });
      console.log("foundRight", foundRight);
      if (foundRight) {
        const obj = { ...foundRight, id: Math.random(), roles: [] } as any;
        const foundExistingRight = this.form.rights.find(
          (formRight) => formRight.key === foundRight.key
        );
        if (!foundExistingRight) {
          this.form.rights.push(obj);
        }
      }
      this.filterEmptyRights();
    });
  }

  async changeExportService() {
    if (!this.exportRightForm.serviceId) return;

    this.exportRightForm.rights = [];

    this.exportedRights = await RolesStore.fetchServiceRights({
      serviceId: Number(this.exportRightForm.serviceId),
    });
  }

  filterEmptyRights() {
    this.form.rights = this.form.rights.filter((r) => !!r.key);
  }

  handleShowRoleForm() {
    this.showExportRightForm = false;
    this.showRoleForm = !this.showRoleForm;
  }

  handleShowExportRightForm() {
    this.showRoleForm = false;
    this.showExportRightForm = !this.showExportRightForm;
  }

  addRow() {
    this.form.rights.push({
      id: Math.random(),
      key: "",
      name: "",
      description: "",
      roles: [],
    });
  }
  deleteRow(row: any) {
    if (this.form.rights.length === 1) return;
    this.form.rights = this.form.rights.filter((r) => r.id !== row.id);
  }
  changeKeyRight(right: any) {
    right.key = right.key.toUpperCase();
  }

  changeKeyRole(role: any) {
    role.key = role.key.toUpperCase();
  }

  open() {
    this.show = true;
  }
  handleClose() {
    console.log("close");
  }
  handleOpen() {
    console.log("open");
    this.init();
  }
  changeService() {
    this.init();
  }
  reset() {
    this.form.rights = [
      {
        id: Math.random(),
        key: "",
        name: "",
        description: "",
        roles: [],
      },
    ];
    this.exportRightForm = {
      serviceId: null,
      rights: [] as string[],
    };
  }
  async fetchServiceRoles() {
    this.loading = true;
    this.rolesList = await RolesStore.fetchServiceRoles({
      serviceId: this.form.serviceId,
    });
    this.loading = false;
  }

  async init() {
    this.form.serviceId = this.$props.serviceId;
    this.reset();
    this.fetchServiceRoles();
  }

  get filteredServiceList() {
    const list = this.$dictionary({
      key: "services",
      type: "list",
    }) as IService[];

    return list.filter((s) => s.id !== this.form.serviceId);
  }
}
