import { IDictionaries } from "@/store/root/interfaces";
import RootStore from "@/store/root";

function dictionary<T extends keyof IDictionaries>(params: {
  key: T,
  type: 'map' | 'list'
}) {
  if (!RootStore.dictionaries) return {}

  return RootStore.dictionaries[params.key] && RootStore.dictionaries[params.key][params.type] as IDictionaries[T]['map' | 'list'];
}

const dictionaryPlugin = {
  install(Vue: any) {
    Vue.prototype.$dictionary = dictionary;
    Vue.$dictionary = dictionary;
  },
};
export default dictionaryPlugin;
