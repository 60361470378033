import {
    VuexModule,
    Module,
    Mutation,
    Action,
    getModule,
  } from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";
import {
    IEventListResponse,
    IEventsList,
    IEventDelete,
} from "@/store/event/interface";
import { AxiosResponse } from "axios";
import { Notification } from "element-ui";

@Module({ dynamic: true, store, name: "Events" })  
class Event extends VuexModule {
  public _eventList = {} as IEventsList;

  @Mutation
  public SET_EVENT_LIST(data: IEventsList): void {
    this._eventList = data;
  }

  @Action({ rawError: true })
  public async getEventsList(options?: {
    filter: {
      [key: string]: any
    };
    sort?: {
      status: "ASC" | "DESC";
    };
  }) {
    const response: AxiosResponse<IEventListResponse> =
      await instanceBackend.post("/events/list", options);        

    this.SET_EVENT_LIST(response.data.result);

    return response.data?.result;
  }

  @Action({ rawError: true })
  public async deleteEvent(id: number) {
    const response: AxiosResponse<IEventDelete> = 
      await instanceBackend.delete(`/events/${id}`);

    if (response.data.success) {
      Notification.success({
        title: "Успешно",
        message: response.data?.result.message,
      });
    }
  } 

  get eventList() {
    return this._eventList;
  }
}

export default getModule(Event);
  