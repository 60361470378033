



























































































































































































import { Component, Mixins } from "vue-mixin-decorator";
import LicenseStore from "@/store/licenses";
import TranslateMixin from "@/mixins/TranslateMixin";
import { DirectionTable } from "@/interfaces";
import { amountDays, formatRangeDates, updateQuery } from "@/utils";
import { ILicense, ILicenseListSort } from "@/store/licenses/interfaces";
import router from "@/router";
import moment from 'moment';
import * as XLSX from 'xlsx';

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  search: "",
  dates: [] as string[],
  accountId: [] as number[],
  status: [] as string[],
  service: [] as string[],
});


@Component({
  components: {},
  mixins: [TranslateMixin],
})
export default class LicensesList extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  selectedLicense = {} as ILicense;
  loading = false;
  showFilters = false;
  sorts = JSON.parse(defaultSorts);
  filters = JSON.parse(defaultFilters);

  additionalColums = {
    activeFrom: false,
    account: false,
    accountOwner: false,
    tariff: false,
  };

  mounted() {
    this.init();
  }

  async init() {
    this.loading = true;
    await LicenseStore.getLicenseList({
      sort: this.sorts,
      ...this.filters,
    });
    this.loading = false;
    this.closeFilters()
  }

  async pageSizeChange(size: number) {
    this.filters.limit = size;
    await this.init();
  }

  async changePage(page: number) {
    this.filters.page = page;
    await this.init();
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof ILicenseListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof ILicenseListSort
    } = {
      createdAt: "createdAt",
      name: "name",
      accountId: "accountId",
      activeFrom: "activeFrom",
      activeTo: "activeTo",
      status: "status",
      tariffId: "tariffId",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: DirectionTable = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.init();
  }

  async handleActions(event: any) {
    if (event.name === "ReadLicense") {
      router.push("/licenses/read/" + event.row.licenseId)
      updateQuery(this, { service: event.row.service });
    }
    if (event.name === "EditLicense") {
      router.push("/licenses/edit/" + event.row.licenseId)
      updateQuery(this, { service: event.row.service });
      updateQuery(this, {...this.filters});
    }
  }  

  changeDate(dates: string[]) {
    this.filters.dates = formatRangeDates(dates) as string[];
  }

  get licenseList() {
    return LicenseStore.licenseList;
  }

  get licenseListStatistic() {
    return LicenseStore.licenseListStatistic.data;
  }

  amountDays(date: string) {
    return amountDays(date)
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.init();
    this.closeFilters()
  }

  closeFilters() {
    this.showFilters = false;
  }

  saveToExcel() {
    let table: any = [['Сервис', 'Название', 'Активен по', 'Статус', 'Аккаунт', 'Владелец', 'Телефон']]

    this.licenseList.data.forEach(license => {
      table.push([ 
        this.translate('serviceNamesMapped', license.service),
        license.name,
        moment( license.activeTo).format('DD-MM-YYYY'),
        this.translate('licenceStatuses', license.status),
        this.translate('accounts', license.accountId),
        this.getAccountOwner(license.accountId),
        this.getMobileAccountOwner(license.accountId)
      ])
    });

    const ws = XLSX.utils.aoa_to_sheet(table);
    ws['!cols'] = this.fitToColumn(table);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'licenses');
    XLSX.writeFile(wb, 'Licenses_' + moment().format('YYYY-MM-DD_HH-mm') + '.xlsx');
  }

  fitToColumn(arrayOfArray: any) {
    return arrayOfArray[0].map((colName: string, colIndex: number) => ({ 
      wch: Math.max(...arrayOfArray.map((cell: string[] | number[]) => cell[colIndex] ? cell[colIndex].toString().length : 0)) 
    }));
  }

  async saveToExcelStatistic() {
    this.loading = true
    const period = [
      moment()
        .subtract(1,'month')
        .startOf('day'),
      moment()
        .add(1,'month')
        .endOf('day')  
    ]

    await LicenseStore.getLicenseListForStatistic({
      sort: "activeTo-ASC",
      dates: [
        period[0].format("YYYY-MM-DD HH:mm:ss"), 
        period[1].format("YYYY-MM-DD HH:mm:ss"),
      ],
      limit: 500,
      page: 1
    });

    const balanseList = await LicenseStore.getBalanceList();

    let table: any = [
      ['', 'Лицензии, которые истекают', '', '', '', '', ''],
      ['', `с ${period[0].format("DD MM YYYY")} по ${period[1].format("DD MM YYYY")}`, '', '', '', '', ''],
      [],
      ['№ ', 'Название организации ', 'Лицензия активна до ', 'Текущий баланс ', 'Стоимость лицензии ', 'Владелец ', 'Телефон ']
    ]

    this.licenseListStatistic?.forEach((license, index) => {
      table.push([ 
        index + 1,
        this.translate('accounts', license.accountId),
        moment( license.activeTo).format('DD-MM-YYYY'),
        balanseList[license.accountId] + " ₽" || '-',
        license.tariff.cost,
        this.getAccountOwner(license.accountId),
        this.getMobileAccountOwner(license.accountId)
      ])
    });

    const ws = XLSX.utils.aoa_to_sheet(table);
    ws['!cols'] = this.fitToColumn(table);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'licenses');
    XLSX.writeFile(wb, 'Licenses_' + moment().format('YYYY-MM-DD_HH-mm') + '.xlsx');
    this.loading = false
  }

}
