
























































































































































import { Component, Vue } from "vue-property-decorator";
import ReceiptStore from "@/store/receipt";
import { IOrganization } from "@/store/organization/interfaces";
import { IReceiptsFilter, IReceiptsSort } from "@/views/receipts/interface";
import GetDataByFdnModal from "@/views/receipts/modals/GetDataByFdnModal.vue";
import { ICashBox } from "@/store/cashbox/interfaces";
import config from "@/config";
import { formatRangeDates, updateQuery } from "@/utils/index";
import SelectOrganizations from "@/components/SelectOrganizations.vue";
import moment from "moment";

const defaultSort = JSON.stringify({
  createdAt: "DESC",
} as IReceiptsSort);

const defaultFilters = JSON.stringify({
  date: [],
  typeReceipt: [],
  cashbox: [],
  organization: [],
  statusReceipt: [],
  summ: { from: 0, to: 0 },
} as IReceiptsFilter);

@Component({
  name: "Receipts",
  components: { GetDataByFdnModal, SelectOrganizations },
})
export default class Receipts extends Vue {
  showFilters = false;
  receipts: any = [];
  cashboxes: any[] = [];
  organizations: IOrganization[] = [];
  pagination = { page: 1, limit: 10 };
  filters = JSON.parse(defaultFilters);
  sort: IReceiptsSort = JSON.parse(defaultSort);
  loading = false;
  additionalColums = {
    externalId: false,
    trouble: false,
    fdJson: false,
    kkt: false,
  };
  selectedReceipt = null;

  mounted() {
    this.init();
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getReceipts();
  }

  closeFilters() {
    this.showFilters = false;
  }

  changeFilterCashbox() {
    if (!this.filters.organization.length) return;

    this.filters.cashbox = this.filters.cashbox.filter((cashbox: any) =>
      this.filteredCashboxes.find((c) => c.id === cashbox)
    );
  }

  changeFilterOrganization() {
    this.changeFilterCashbox();
  }

  initFilters() {
    if(this.$route.query?.date && this.$route.query?.date[0] && this.$route.query?.date[1]) {
      this.filters.date = this.$route.query.date
    }
    else {
      this.filters.date[0] = moment().startOf('day').subtract(7, 'day').utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
      this.filters.date[1] = moment().endOf('day').utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
    }

    if(this.$route.query?.statusReceipt) {
      if(this.$route.query.statusReceipt instanceof Array){
        this.filters.statusReceipt = (this.$route.query.statusReceipt as any[]).map((s) => Number(s));
      }
      else {
        this.filters.statusReceipt[0] = Number(this.$route.query.statusReceipt) 
      }
      
    }
    else {
      this.filters.statusReceipt = this.receiptStatusesList
        .filter((s) => +s.key !== 0)
        .map((s) => +s.key);
    }

    if(this.$route.query?.organization) {
      if(this.$route.query.organization instanceof Array){
        this.filters.organization = (this.$route.query.organization as any[]).map((s) => Number(s));
      }
      else {
        this.filters.organization[0] = Number(this.$route.query.organization) 
      }
    }

    if(this.$route.query?.typeReceipt) {
      if(this.$route.query.typeReceipt instanceof Array){
        this.filters.typeReceipt = (this.$route.query.typeReceipt as any[]).map((s) => Number(s));
      }
      else {
        this.filters.typeReceipt[0] = Number(this.$route.query.typeReceipt) 
      }
    }
    
    if(this.$route.query?.cashbox) {
      if(this.$route.query.cashbox instanceof Array){
        this.filters.cashbox = (this.$route.query.cashbox as any[]).map((s) => Number(s));
      }
      else {
        this.filters.cashbox[0] = Number(this.$route.query.cashbox) 
      }
    }
  }

  async init() {
    this.initFilters();
    await this.getReceipts();
  }

  prepareFilters() {
    const options = {
      filter: {
        typeReceiptId: this.filters.typeReceipt,
        cashboxId: this.filters.cashbox,
        organizationId: this.filters.organization,
        status: this.filters.statusReceipt,
        date: this.filters.date,
      },
      range: {
        sumDoc: {
          from: this.filters.summ.from,
          to: this.filters.summ.to,
        },
        createdAt: {
          from: "",
          to: "",
        },
      },
    };

    if (this.filters.date?.length) {
      options.range.createdAt.from = this.filters.date[0];
      options.range.createdAt.to = this.filters.date[1];
    }

    return options;
  }

  async getReceipts() {
    this.showFilters = false;
    this.loading = true;
    this.receipts = await ReceiptStore.getReceipts({
      ...this.pagination,
      ...this.prepareFilters(),
      sort: this.sort,
    });
    this.loading = false;
    updateQuery(this, {...this.filters});
  }

  async pageSizeChange(e: number) {
    this.pagination.limit = e;
    this.getReceipts();
  }

  async changePage(e: number) {
    this.pagination.page = e;
    this.getReceipts();
  }

  async changeSort(e: {
    column: string;
    prop: keyof IReceiptsSort;
    order: string;
  }): Promise<void> {
    this.sort = {};

    const sortMap: { [key: string]: keyof IReceiptsSort } = {
      createdAt: "createdAt",
      receiptType: "typeReceiptId",
      cashboxid: "cashboxId",
      status: "status",
      sumdoc: "sumDoc",
      externId: "externId",
    };

    const direction = e.order === "descending" ? "DESC" : "ASC";

    if (!sortMap[e.prop]) return;

    this.sort[sortMap[e.prop]] = direction;

    this.getReceipts();
  }

  handleActions(event: any) {
    if (event.name === "ReturnInQueue") {
      ReceiptStore.resendReceipts(event.row);
      this.getReceipts();
    }
    if (event.name === "GetDataByReceipt") {
      ReceiptStore.updateFiscalData(event.row);
      this.getReceipts();
    }
    if (event.name === "GetDataByFdn") {
      this.selectedReceipt = event.row;
      const modal: any = this.$refs["GetDataByFdnModal"];

      modal.open();
    }
  }

  changeDate(dates: string[]) { 
    this.filters.date = dates ? formatRangeDates(dates) : [];
  }

  async openCheck(id: number){
    window.open(config.linkCheck.host + "/info/" + await ReceiptStore.getReceiptUuid(id), '_blank');
  }

  get receiptStatusesList() {
    return ReceiptStore.receiptStatusesList;
  }

  get receiptStatusesMap() {
    return ReceiptStore.receiptStatusesMap;
  }

  get receiptTypesList() {
    return ReceiptStore.receiptTypesList;
  }

  get filteredCashboxes() {
    const filtered = [
      ...(this.$dictionary({
        key: "cashbox",
        type: "list",
      }) as ICashBox[]),
    ];
    if (!this.filters.organization || !this.filters.organization.length)
      return filtered;

    return filtered.filter((cashbox) => {
      return cashbox.organizationId
        ? this.filters.organization.includes(cashbox.organizationId)
        : false;
    });
  }
}
