

























































































































































































import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";
import CashboxStore from "@/store/cashbox";
import WorkerStore from "@/store/worker/index";
import { ICashboxListSort } from "@/store/cashbox/interfaces";
import { updateQuery } from "@/utils";
import CashBoxModal from "@/components/modals/CashBoxModal.vue";
import CashBoxExtendedModal from "@/components/modals/CashBoxExtendedModal.vue";
import FiscalMemoryModal from "@/views/bids/modals/FiscalMemoryModal.vue";
import { ICashBox } from "@/store/cashbox/interfaces";
import { IWorker } from "@/store/worker/interfaces";
import moment from "moment";
import { formatRangeDates } from "@/utils/index";
import { Notification } from "element-ui";
import SelectOrganizations from "@/components/SelectOrganizations.vue";

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  search: "",
});

const defaultFilterArrays = JSON.stringify({
  organizationId: [],
  cashboxModelId: [],
  ffdId: [],
  snoId: [],
  status: [],
  workerId: [],
  fnDates: [],
});

const defaultSorts = JSON.stringify({
  id: "DESC",
});

@Component({
  components: { SelectOrganizations, CashBoxModal, CashBoxExtendedModal, FiscalMemoryModal },
  name: "CashboxesPage",
})
export default class CashboxesPage extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  loading = false;
  show = false;
  showFilters = false;
  additionalColums = {
    organizationName: true,
    accountName: false,
    accountOwner: false,
    sno: false,
    cashboxModelId: false,
    needPrintOnPaper: false,
    bidId: false,
    comment: false,
    workerName: false,
  };

  $refs!: {
    CashBoxModal: CashBoxModal;
    CashBoxExtendedModal: CashBoxExtendedModal;
    FiscalMemoryModal: FiscalMemoryModal;
  };

  filters = JSON.parse(defaultFilters);
  filterArrays = JSON.parse(defaultFilterArrays);
  sorts = JSON.parse(defaultSorts);

  selectedCashBox = {} as ICashBox;

  mounted() {
    this.init();
  }

  async init() {
    await WorkerStore.getWorkers();   
    this.initFilters() 
    await this.getCashboxList();
  }

  initFilters() {
    this.filterArrays.workerId = this.workerList
      .filter((worker: IWorker) => worker.host !== "virtual")
      .map((worker: IWorker) => worker.id);
  }

  async getCashboxList() {
    this.loading = true;
    await CashboxStore.getCashboxesList({
      filter: {
        ...this.filters,
        ...Object.fromEntries(Object.entries(this.filterArrays).filter(element => this.filterArrays[element[0]].length))
      },
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getCashboxList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getCashboxList();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.filterArrays = JSON.parse(defaultFilterArrays);
    this.getCashboxList();
  }

  async changeSort(e: {
    column: string;
    prop: keyof ICashboxListSort;
    order: string;
  }): Promise<void> {
    this.sorts = {};

    const sortMap: { [key: string]: keyof ICashboxListSort } = {
      cashboxModelId: "cashboxModelId",
      id: "id",
      status: "status",
      organizationName: "organizationName",
      address: "address",
      fnExpinTime: "fnExpinTime",
    };

    if (!sortMap[e.prop]) return;

    if(e.order === null){
      this.sorts[sortMap["id"]] = "DESC"
    }
    else{
      const direction =  e.order === "descending" ? "DESC" : "ASC"
      this.sorts[sortMap[e.prop]] = direction;
    }
    
    this.getCashboxList();
  }

  changeDate(dates: string[]) {
    this.filterArrays.fnDates = formatRangeDates(dates);
  }

  async changeSearch() {
    // updateQuery(this, this.filters.search);
    this.getCashboxList();
  }
  
  async handleActions(event: any) {
    this.selectedCashBox = event.row;

    if (event.name === "EditCashbox") {
      this.$refs.CashBoxModal.open();
    }

    if (event.name === "GetExtendedData") {
      await CashboxStore.getExtendedData(event.row.id);
    }

    if (event.name === "ShowExtendedData") {
      this.$refs.CashBoxExtendedModal.open();
    }

    if (event.name === "FiscalMemoryModal") {
      this.$refs.FiscalMemoryModal.open();
    }

    if (event.name === "GetHardware") {
      this.loading = true;
      await CashboxStore.getHardware(this.selectedCashBox.id);
      Notification.success({
        title: "Успешно",
        message: "Запрос данных отправлен на кассу",
      });
      setTimeout(() => {
        this.getCashboxList();
        this.loading = false;
      }, 2000);
    }
  }

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  async handleOpen() {
    await this.init();
  }

  get cashboxesList() {
    return CashboxStore.cashboxList;
  }

  get workerList() {
    return WorkerStore.workerList;
  }

  amountDays(date: string){
    if (!date) return 0;
    return moment(date).diff(moment(), 'days')
  }
}
