import moment from "moment";

export const updateQuery = (vue: any, obj: any) => {
  vue.$router.replace({
    query: { ...vue.$route.query, ...obj },
  });
};

export const generateRandomString = (length: number, onlyNumbers = false) => {
  const result = [];
  const characters = onlyNumbers
    ? "0123456789"
    : "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

export const formatRangeDates = (dates: string[]) => {
  if (dates[0] && dates[1]) {
    dates[0] = moment(dates[0])
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    dates[1] = moment(dates[1])
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    return dates;
  }
}

export const formatRangeDatesUtc = (dates: string[]) => {
  if (dates[0] && dates[1]) {
    dates[0] = moment(dates[0])
      .startOf("day")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    dates[1] = moment(dates[1])
      .endOf("day")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    return dates;
  }
}

export const amountDays = (date: string) => {
  if (!date) return 0;
  return moment(date).diff(moment(), 'days')
}
