import {
  VuexModule,
  Module,
  Action,
  getModule,
  Mutation,
} from "vuex-module-decorators";
import { instanceAms } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import {
  IAccountsListResponse,
  IUsersListResponse,
  ICreateUser,
  IUser,
  IGetUser,
  IUpdateUser
} from "@/store/account/interfaces";
import RootStore from "@/store/root";
import { Notification } from "element-ui";

@Module({ dynamic: true, store, name: "Account" })
class Account extends VuexModule {
  private _usersList = {} as IUsersListResponse;
  private _accountsList = {} as IAccountsListResponse;

  @Mutation
  public SET_USERS_LIST(data: IUsersListResponse): void {
    this._usersList = data;
  }

  @Mutation
  public SET_ACCOUNTS_LIST(data: IAccountsListResponse): void {
    this._accountsList = data;
  }

  @Action({ rawError: true })
  public async getUsersList(filters: {
    page?: number;
    limit?: number;
    serviceId?: number;
    accountId?: number;
    search?: string;
  }) {
    const response: AxiosResponse<IUsersListResponse> = await instanceAms.get(
      "/users",
      {
        params: filters,
      }
    );

    this.SET_USERS_LIST(response.data);
  }

  @Action({ rawError: true })
  public async getUserById(id: number){
    const response: AxiosResponse<IGetUser> =
      await instanceAms.get(
        `/users/${id}`,
        {
          params: {
            servicesRights : true
          },
        }
      );

    return response.data  
  }

  @Action({ rawError: true })
  public async getAccountList() {
    const response: AxiosResponse<IAccountsListResponse> =
      await instanceAms.get("/accounts");

    if (response && response.data) {
      this.SET_ACCOUNTS_LIST(response.data);
      RootStore.SET_DICTIONARIES({
        data: response.data.data,
        field: "accounts",
      });
    }
  }

  @Action({ rawError: true })
  public async editUser(user: IUpdateUser) {
    const response: AxiosResponse<ICreateUser> =
      await instanceAms.patch(`/users`, user); 

    return this.responseValid(response.data);
  }

  @Action({ rawError: true })
  public async createUser(user: IUser) {
    const response: AxiosResponse<ICreateUser> =
      await instanceAms.post(`/accounts/invite`, user); 

    return this.responseValid(response.data);
  }

  @Action({ rawError: true })
  public async responseValid(response: ICreateUser) {
    if (response.success) {
      Notification.success({
        title: "Успешно",
        message: "Пользователь добавлен",
      });
      return true;
    }

    if (response.errors) {
      Notification.error({
        title: "Ошибка",
        message: 
             response.errors.lastName?.messages[0]
          || response.errors.firstName?.messages[0]
          || response.errors.mobile?.messages[0]
          || response.errors.email?.messages[0]
          || response.errors.password?.messages[0]
          || response.errors.confirmPassword?.messages[0]
          || response.errors.accountId?.messages[0]
          || response.errors.serviceId?.messages[0]
          || response.errors.rolesRights?.messages[0]
          || response.errors.roles?.messages[0]
          || "Данные введены некорректно",
      });
    }
    return false;
  }

  get usersList() {
    return this._usersList;
  }

  get accountsList() {
    return this._accountsList.data;
  }
}

export default getModule(Account);
