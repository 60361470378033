






import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";
import AgreementStore from '@/store/agreement/index';

@Component({
  components: {},
  mixins: [TranslateMixin],
})
export default class OpenAgreementFile extends Mixins<TranslateMixin>(TranslateMixin) {
  screenWidth = window.screen.width
  screenHeight = window.screen.height

  mounted() {
    this.updateWidth()
    this.getAgreementFile()
  }

  async getAgreementFile() {
    await AgreementStore.getAgreementFile(this.$route.params.id as string);
  }

  created() {
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.screenWidth = window.innerWidth - 100;
    this.screenHeight = window.innerHeight - 100;
  }

  get agreementFile() {
    return AgreementStore.agreementFile;
  }
}
