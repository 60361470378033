import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceQR } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import {
  IQrLicense,
  IQrListResponse,
  IQrRegisterResponse,
  IQrLicenseStatus,
  IQrRegisterStatus,
} from "@/store/qr/interfaces";

@Module({ dynamic: true, store, name: "QR" })
class QR extends VuexModule {
  public _qrList: IQrListResponse = {} as IQrListResponse;
  public _qrRegisterList: IQrRegisterResponse = {} as IQrRegisterResponse;
  public _qrLicence: IQrLicense = {} as IQrLicense;

  @Mutation
  public SET_QR_LIST(data: IQrListResponse): void {
    this._qrList = data;
  }

  @Mutation
  public SET_QR_REGISTER_LIST(data: IQrRegisterResponse): void {
    this._qrRegisterList = data;
  }

  @Mutation
  public SET_QR_LICENCE(data: IQrLicense): void {
    this._qrLicence = data;
  }

  @Action({ rawError: true })
  public async getQrList(filters: {
    page: number;
    limit: number;
    dates: string[];
    accountId: number[];
    isBindedToDevice: boolean[];
    status: string[];
    sort: string[];
  }) {
    const response: AxiosResponse<IQrListResponse> = await instanceQR.get(
      "/api/licenses",
      { params: filters }
    );

    this.SET_QR_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async getQrRegisterList(filters: {
    page: number;
    limit: number;
    dates: string[];
  }) {
    const response: AxiosResponse<IQrRegisterResponse> = await instanceQR.get(
      "/api/regRequests",
      { params: filters }
    );

    this.SET_QR_REGISTER_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async getQrLicence(licenceId: number) {
    const response: AxiosResponse<IQrLicense> = await instanceQR.get(
      `/api/licenses/${licenceId}`
    );

    this.SET_QR_LICENCE(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async updateQrLicence(options: {
    licenseId: number;
    form: Partial<IQrLicense>;
  }) {
    const response: AxiosResponse<IQrListResponse> = await instanceQR.put(
      `/api/licenses/${options.licenseId}`,
      options.form
    );

    this.SET_QR_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async unbindDevice(licenseId: string) {
    // await instanceQR.put(`/api/licenses/${licenseId}/unbind`);
  }

  get qrList() {
    return this._qrList;
  }

  get qrRegisterList() {
    return this._qrRegisterList;
  }

  get qrLicence() {
    return this._qrLicence;
  }

  get qrLicenseStatuses() {
    return [
      {
        id: "active",
        name: "Активна",
      },
      {
        id: "inactive",
        name: "Неактивна",
      },
      {
        id: "frozen",
        name: "Приостановлена",
      },
    ] as IQrLicenseStatus[];
  }

  get qrRegisterStatuses() {
    return [
      {
        id: "new",
        name: "Новая",
      },
      {
        id: "done",
        name: "Активна",
      },
      {
        id: "confirmed",
        name: "Подтвержденная",
      },
      {
        id: "expired",
        name: "Истекшая",
      },
    ] as IQrRegisterStatus[];
  }
}

export default getModule(QR);
