















































































import { Component, Mixins } from "vue-mixin-decorator";
import router from "@/router";
import LicenseStore from "@/store/licenses";
import { ILicense } from "@/store/licenses/interfaces";
import TRANSPORT from "@/views/licenses/components/ReadTransportLicense.vue";
import PAYBOX from "@/views/licenses/components/ReadPayboxLicense.vue";
import TranslateMixin from "@/mixins/TranslateMixin";

const initForm = JSON.stringify({
    name: "",
    serviceName: ""
});

@Component({
  mixins: [TranslateMixin],
  components: { TRANSPORT, PAYBOX },
})

export default class ReadLicense extends Mixins<TranslateMixin>(
  TranslateMixin
)  {
  show = false
  load = false;
  activeSections = ['all', 'tariff']
  license = {} as ILicense
  form = JSON.parse(initForm)

  mounted() {
    this.show = true;
    this.getBillingLicense()
  }

  async getBillingLicense() {
    this.load = true;
    this.license = await LicenseStore.getLicense(this.$route.params.id as string); 
    this.load = false;
  }

  handleClose() {
    router.push('/licenses')
    this.show = false;
  }

  refreshForm() {
    this.form = JSON.parse(initForm)
  }

}
