

















































































import { Component } from "vue-property-decorator";
import { Prop, Mixins } from "vue-property-decorator";
import { IProduct } from "@/store/product/interfaces";
import { ITariff } from "@/store/tariff/interfaces";
import TranslateMixin from "@/mixins/TranslateMixin";
import ProductStore from "@/store/product/index";
import TariffStore from "@/store/tariff/index";
import InputPlaceholder from "@/components/Input.vue"

const initProductForm = JSON.stringify({
  id: 0,
  name: "",
  serviceName: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: ""
});

const initTariffForm = JSON.stringify({
  productId: '',
  description: "",
  duration: "",
  freePeriodType: "",
  cost: ""
});

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 5
});

@Component({
  components: { InputPlaceholder },
  mixins: [TranslateMixin],
})

export default class EditProductModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {

  @Prop({ required: true }) readonly productId!: string;

  show = false

  loading = false

  editableRow = null;

  productForm = JSON.parse(initProductForm) as IProduct;

  tariffForm = JSON.parse(initTariffForm) as ITariff;

  filters = JSON.parse(defaultFilters);

  collapseOpened = [];

  async init() {
    this.loading = true;
    await this.getTariffList();
    await ProductStore.getProduct(this.productId);
    this.productForm = { ...this.productForm, ...this.product };
    this.loading = false;
  }

  open() {
    this.show = true;
  }

  handleClose() {
    this.show = false;
    this.editableRow = null;
    this.$emit("reload")
  }

  async handleOpen() {
    await this.init();
  }

  async handleActions(event: any) {
    if(event.name === "CreateTariff") {
      this.tariffForm.productId = this.productForm.id
      await TariffStore.createTariff(this.tariffForm)
    }

    if(event.name === "UpdateProduct") {
      await ProductStore.updateProduct({
        id: this.productForm.id,
        name: this.productForm.name,
        serviceName: this.productForm.serviceName
      })
    }

    if(event.name === "UpdateTariff") {
      await TariffStore.updateTariff(this.editableRow as unknown as ITariff);
      this.editableRow = null;
    }

    if (event.name === "EditTariff") {
      this.editableRow = event.row;
    }
    
    if(event.name === "CancelUpdate") {
      this.editableRow = null;
    }

    if (event.name === "RemoveTariff") {
      this.$confirm(
        `Вы действительно хотите удалить тариф "${event.row.description}"?`,
        "Внимание!",
        {
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await TariffStore.removeTariff(event.row.id);
        await this.refreshForm()
      });
    }

    await this.refreshForm()
  }

  async refreshForm() {
    this.tariffForm = JSON.parse(initTariffForm)
    await this.init()
  }

  async submit() {
    this.show = false;
    this.$emit("reload");
  }

  async getTariffList() {
    this.loading = true
    await TariffStore.getTariffsList({
      productId: this.productId, 
      page: this.filters.page,
      limit: this.filters.limit
    });
    this.loading = false
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getTariffList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getTariffList();
  }

  get product() {
    return ProductStore.product;
  }

  get tariffList() {
    return TariffStore.tariffsList;
  }
}
