























































import { IWorker } from "@/store/worker/interfaces";
import CashBoxesModal from "@/views/workers/modals/CashBoxesModal.vue";
import CreateWorkerModal from "@/views/workers/modals/CreateWorkerModal.vue";
import ExportWorkerSettingsModal from "@/views/workers/modals/ExportWorkerSettingsModal.vue";

import { Component, Vue } from "vue-property-decorator";
import WorkerStore from "@/store/worker/index";

const defaultFilters = JSON.stringify({
  search: "",
});

@Component({
  components: { CashBoxesModal, CreateWorkerModal, ExportWorkerSettingsModal },
})
export default class WorkersPage extends Vue {
  workers: IWorker[] = [];
  selectedWorker = {} as IWorker;
  loading = true;
  filters = JSON.parse(defaultFilters);

  async mounted() {
    this.getWorkers();
  }

  async getWorkers() {
    this.loading = true;

    await WorkerStore.getWorkers({...this.filters});

    this.workers = WorkerStore.workers;

    this.loading = false;
  }

  handleActions(event: any) {
    this.selectedWorker = event.row;

    if (event.name === "WorkerCashBoxes") {
      const modal: any = this.$refs["CashBoxesModal"];

      modal.open();
    }

    if (event.name === "ExportWorkerSettings") {
      const modal: any = this.$refs["ExportWorkerSettingsModal"];

      modal.open();
    }

    if (event.name === "RemoveWorker") {
      this.$confirm(
        `Вы действительно хотите удалить воркер ${this.selectedWorker.name} ?`,
        "Осторожно",
        {
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await WorkerStore.deleteWorker(this.selectedWorker.id);
        this.getWorkers();
      });
    }
  }

  openCreateWorkerModal() {
    const modal: any = this.$refs["CreateWorkerModal"];

    modal.open();
  }
}
