
























import { Component, Vue } from "vue-property-decorator";
import Roles from "@/views/roleSettings/Roles.vue";
import Rights from "@/views/roleSettings/Rights.vue";
import UserRights from "@/views/roleSettings/UserRights.vue";
import { updateQuery } from "@/utils";

@Component({ components: { Roles, Rights, UserRights } })
export default class Menu extends Vue {
  activeTab = this.$route.query.activeTab || "Rights";
  selectedService = +this.$route.query.serviceId || 1;

  changeService() {
    updateQuery(this, { serviceId: String(this.selectedService) });
  }

  tabClick() {
    updateQuery(this, { activeTab: this.activeTab });
  }
}
