import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {
  IWorker,
  IWorkerListResponse,
  IWorkerResponse,
} from "@/store/worker/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPaginationParams, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "Workers" })
class Worker extends VuexModule {
  public _workers: IWorker[] = [];
  public _worker = {} as IWorker;
  public _workerList: IWorker[] = [] 

  @Mutation
  public SET_WORKERS(workers: IWorkerListResponse): void {
    this._workers = workers?.result;
  }

  @Mutation
  public SET_WORKER(worker: IWorker): void {
    this._worker = worker;
  }

  @Mutation
  public SET_WORKERS_LIST(worker: IWorker[]): void {
    this._workerList = worker;
  }

  @Action({ rawError: true })
  public async getWorkers(filters?: 
    IPaginationParams & 
    ISortSearch
  ) {
    const response: AxiosResponse<IWorkerListResponse> =
      await instanceBackend.get("/worker/list", { params: filters });

    this.context.commit("SET_WORKERS", response.data);

    this.context.commit("SET_WORKERS_LIST", response.data?.result);

    return response;
  }

  @Action({ rawError: true })
  public async getWorker(id: number) {
    const response: AxiosResponse<IWorkerResponse> = await instanceBackend.get(
      `/worker/info/${id}`
    );

    if (response.data && response.data.result) {
      this.context.commit("SET_WORKER", response.data.result);
    }

    return response.data;
  }

  @Action({ rawError: true })
  public async createWorker(body: any) {
    await instanceBackend.post("/worker/self", body);
  }

  @Action({ rawError: true })
  public async deleteWorker(id: number) {
    await instanceBackend.delete(`/worker/self/${id}`);
  }

  @Action({ rawError: true })
  public async updateWorker(worker: { id: number; body: any }) {
    await instanceBackend.put(`/worker/${worker.id}`, worker.body);
  }

  @Action({ rawError: true })
  public async attachCashBoxes(form: {
    workerId: number;
    cashboxes: number[];
  }) {
    await instanceBackend.post(`worker/${form.workerId}/attach/cashboxes`, {
      cashboxes: form.cashboxes,
    });
  }

  @Action({ rawError: true })
  public async removeWorkerCashbox(cashboxId: number) {
    await instanceBackend.delete(`worker/cashbox/${cashboxId}`);
  }

  get workers() {
    return this._workers;
  }

  get workerList() {
    return this._workerList;
  }
}

export default getModule(Worker);
