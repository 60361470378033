











































































import { Component, Mixins } from "vue-mixin-decorator";
import WorkerStore from "@/store/worker";
import CashBoxStore from "@/store/cashbox";
import { IWorker } from "@/store/worker/interfaces";
import TranslateMixin from "@/mixins/TranslateMixin";
import { ICashboxesList } from "@/store/cashbox/interfaces";

@Component({
  props: {
    workerId: { type: Number },
  },
  mixins: [TranslateMixin],
})
export default class CashBoxesModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  show = false;

  worker = {} as IWorker;

  freeCashBoxes = {} as ICashboxesList;

  load = false;

  cashboxesForm = {
    cashboxes: [],
  };

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  async fetchWorkerInfo() {
    this.load = true;
    const workerInfo = await WorkerStore.getWorker(+this.$props.workerId);
    this.load = false;

    this.worker = workerInfo.result;
  }

  async fetchFreeCashboxes() {
    this.freeCashBoxes = await CashBoxStore.getCashboxesList({
      filter: {
        workerId: [null],
      },
    });
  }

  async handleOpen() {
    await this.init();
  }

  async init() {
    await this.fetchWorkerInfo();
    await this.fetchFreeCashboxes();
  }

  async addCashboxes() {
    await WorkerStore.attachCashBoxes({
      workerId: +this.worker.id,
      cashboxes: this.cashboxesForm.cashboxes,
    });

    this.fetchWorkerInfo();
    this.fetchFreeCashboxes();
    this.refreshForm();
    this.$emit("reload");
  }

  refreshForm() {
    this.cashboxesForm.cashboxes = [];
  }

  async handleActions(event: any) {
    if (event.name === "RemoveWorkerCashbox") {
      await WorkerStore.removeWorkerCashbox(event.row.id);
      this.init();
      this.$emit("reload");
    }
    if (event.name === "ReloadConnectionCashbox") {
      await CashBoxStore.initWorkerConnection({ cashboxes: [event.row.id] });
      this.init();
      this.$emit("reload");
    }
  }
}
