import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import WorkersSection from "@/views/workers/index.vue";
import BidsPage from "@/views/bids/index.vue";
import CashboxesPage from "@/views/cashboxes/Index.vue";
import ReceiptsSection from "@/views/receipts/index.vue";
import EventsPage from "@/views/events/Index.vue";
import SystemEventsSection from "@/views/systemEvents/SystemEventsSection.vue";
import RoleSettings from "@/views/roleSettings/Index.vue";
import QRLicense from "@/views/qrLicense/index.vue";
import QRRegister from "@/views/qrRegister/Index.vue";
import TransportLicense from "@/views/transportLicense/Index.vue";
import TransportRegister from "@/views/transportRegister/Index.vue";
import Users from "@/views/users/Index.vue";
import Products from "@/views/products/Index.vue";
import BillingEvents from "@/views/billingEvents/Index.vue";
import Licenses from "@/views/licenses/Index.vue";
import ReadLicense from "@/views/licenses/modals/ReadLicense.vue";
import EditLicense from "@/views/licenses/modals/EditLicense.vue";
import AgreementPage from '@/views/agreement/index.vue'
import OpenAgreementPage from '@/views/agreement/modals/OpenAgreementFile.vue'
import AuthStore from "@/store/auth";
import { Notification } from "element-ui";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/workers",
    name: "workers",
    component: WorkersSection,
    meta: {
      access: ["WORKERS"],
    },
  },
  {
    path: "/bids",
    name: "BidsPage",
    component: BidsPage,
    meta: {
      access: ["BIDS"],
    },
  },
  {
    path: "/cashboxes",
    name: "CashboxesPage",
    component: CashboxesPage,
    meta: {
      access: ["CASHBOXES"],
    },
  },
  {
    path: "/receipts",
    name: "receipts",
    component: ReceiptsSection,
    meta: {
      access: ["RECEIPTS"],
    },
  },
  {
    path: "/events",
    name: "events",
    component: EventsPage,
    meta: {
      access: ["EVENTS"],
    },
  },
  {
    path: "/systemEvents",
    name: "systemEvents",
    component: SystemEventsSection,
  },
  {
    path: "/roleSettings",
    name: "roleSettings",
    component: RoleSettings,
    meta: {
      access: ["SUPER_ADMIN"],
    },
  },
  // {
  //   path: "/qrLicense",
  //   name: "qrLicense",
  //   component: QRLicense,
  //   meta: {
  //     access: ["QR_LICENSE"],
  //   },
  // },
  // {
  //   path: "/qrRegister",
  //   name: "qrRegister",
  //   component: QRRegister,
  //   meta: {
  //     access: ["QR_REGISTER"],
  //   },
  // },
  // {
  //   path: "/transportLicense",
  //   name: "transportLicense",
  //   component: TransportLicense,
  //   meta: {
  //     access: ["TRANSPORT_LICENSE"],
  //   },
  // },
  {
    path: "/transportRegister",
    name: "transportRegister",
    component: TransportRegister,
    meta: {
      access: ["TRANSPORT_REGISTER"],
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      access: ["USERS"],
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      access: ["SUPER_ADMIN"],
    },
  },
  {
    path: "/licenses",
    name: "Licenses",
    component: Licenses,
    children: [
      {
        path: 'read/:id',
        component: ReadLicense,
      },
      {
        path: 'edit/:id',
        component: EditLicense,
      },
    ],
  },
  {
    path: "/billingEvents",
    name: "BillingEvents",
    component: BillingEvents
  },
  {
    path: '/agreements',
    name: 'agreement',
    component: AgreementPage
  },
  {
    path: '/agreements/open/:id',
    name: 'openAgreement',
    component: OpenAgreementPage
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL || "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const metaRights = to.meta && to.meta.access ? to.meta.access : [];
  const checkAccess = Vue.$access([metaRights].flat());
  if (to.meta && to.meta.access && !checkAccess) {
    Notification.error({
      title: "Доступ запрещен",
      message: "У Вас нету прав на использование данного раздела",
    });

    router.push("/");
  }

  if (!AuthStore.loggedIn && to.path !== "/") {
    Notification.error({
      title: "Доступ запрещен",
      message: "Нужно авторизоваться",
    });
    router.push("/");
  }

  next();
});

export default router;
