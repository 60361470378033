


































































import BidCashBoxesModal from "@/views/bids/modals/BidCashBoxesModal.vue";
import BidModal from "@/views/bids/modals/BidModal.vue";
import SelectOrganizations from "@/components/SelectOrganizations.vue";

import { Component, Mixins } from "vue-mixin-decorator";

import BidStore from "@/store/bid";
import { IBid, IBidListSort } from "@/store/bid/interface";
import TranslateMixin from "@/mixins/TranslateMixin";

const defaultSorts = JSON.stringify({
    id: "DESC",
});

@Component({
  components: { SelectOrganizations, BidCashBoxesModal, BidModal },
  mixins: [TranslateMixin],
})
export default class BidsPage extends Mixins<TranslateMixin>(TranslateMixin) {
  $refs!: {
    BidCashBoxesModal: BidCashBoxesModal;
    BidModal: BidModal;
  };

  selectedBid = {} as IBid;
  loading = true;

  filters = {
    status: [],
    organization: [],
  };
  
  sorts = JSON.parse(defaultSorts);

  pagination = { page: 1, limit: 10 };

  async mounted() {
    this.getBids();
  }

  async getBids() {
    this.loading = true;
    await BidStore.getBids(this.prepareFilters());
    this.loading = false;
  }

  handleActions(event: any) {
    this.selectedBid = event.row;

    if (event.name === "BidCashBoxesModal") this.$refs.BidCashBoxesModal.open();
    if (event.name === "BidModal") this.$refs.BidModal.open();
  }

  changePage(e: number) {
    this.pagination.page = e;
    this.getBids();
  }

  pageSizeChange(e: number) {
    this.pagination.limit = e;
    this.getBids();
  }

  prepareFilters() {
    const filters = {
      filter: {
        status: this.filters.status,
        organizationId: this.filters.organization,
      },
      range: { from: null, to: null },
      ...this.pagination,
      sort: this.sorts,
    };

    return filters;
  }

  async changeSort(e: {
    column: string;
    prop: keyof IBidListSort;
    order: string;
  }): Promise<void> {
    this.sorts = {};

    const sortMap: { [key: string]: keyof IBidListSort } = {
      createdAt: "createdAt",
      status: "status",
      id: "id",
      amount: "amount",
      isLocal: "isLocal",
    };

    if (!sortMap[e.prop]) return;

    if(e.order === null){
      this.sorts[sortMap["id"]] = "DESC"
    }
    else{
      const direction =  e.order === "descending" ? "DESC" : "ASC"
      this.sorts[sortMap[e.prop]] = direction;
    }
    this.getBids();
  }


  get bidStatuses() {
    return BidStore.bidStatuses;
  }

  get bids() {
    return BidStore.bids;
  }
}
