import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import config from "@/config";
import { instanceAms, instanceLayer } from "@/plugins/axios";
import { IMeResponse, IService, IServicesList, IServicesMe } from "@/store/auth/interfaces";
import { TokenStorage } from "@/store/tokenStorage";
import store from "@/store";
import { AxiosResponse } from "axios";
import { MessageBox } from 'element-ui'

@Module({ dynamic: true, store, name: "Auth" })
class Auth extends VuexModule {
  public user: IMeResponse = {} as IMeResponse;
  public servicesUser = [] as any[];
  public services = {} as IService[];
  public loggedIn = false;

  @Mutation
  public SET_USER(data: IMeResponse): void {
    this.loggedIn = true;
    this.user = data;
  }

  @Mutation
  public LOGOUT(): void {
    TokenStorage.clear();
    this.loggedIn = false;
    this.user = {} as IMeResponse;
  }

  @Mutation
    public SET_SERVICES_USER(data: any[]): void {
    this.servicesUser = data;
  }

  @Mutation
    public SET_SERVICES(data: IServicesList): void {
    this.services = data.data;
  }

  @Action({ rawError: true })
  public async login() {
    try {
      const token = TokenStorage.getAccessToken();
      if (!token) {
        const response: any = await instanceLayer.get("api/user/ctxToken");

        if (!response && !response.data) return;

        if (response.data && response.data === "no session") {
          window.location.href = `${config.layer.host}/api/auth/login`;
          return;
        }

        TokenStorage.setAccessToken(response.data);
      }
      
      const response = await this.getMe();
      if(!response){
        await this.showMessageLogout()
      }
    } catch (error) {
      console.log(error);
    }
  }

  @Action({ rawError: true })
  public async showMessageLogout(){
    MessageBox.confirm(
      `Для использования сервиса требуется завершение текущего сеанса и подтверждение учетной записи. Продолжить?`,
      "Внимание!",
      {
        confirmButtonText: "Продолжить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--danger",
        type: "error",
      }
    ).then(async () => {
      await this.logout()
    });
  }

  @Action({ rawError: true })
  public async logout() {
    this.LOGOUT();
    window.location.href = `${config.layer.host}/api/auth/logout`;
  }

  @Action({ rawError: true })
  public async getMe() {
    try {
      const response: AxiosResponse<IMeResponse> = await instanceAms.get(
        "/accounts/me"
      );
      if (response && response.data) {
        this.SET_USER(response.data);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  @Action({ rawError: true })
  public async getServicesMe() {
    try {
      const response: AxiosResponse<IServicesMe[]> = await instanceAms.get(
        "/services/me"
      );
      if (response && response.data) 
        this.SET_SERVICES_USER(response.data);

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  @Action({ rawError: true })
  public async getServices() {
    const response: AxiosResponse<IServicesList> = await instanceAms.get(
      "/services"
    );
    if (response && response.data) {
      this.SET_SERVICES(response.data);
    }
    return response;
  }

  get userInfo(): IMeResponse {
    return this.user;
  }

  get loginState(): boolean {
    return this.loggedIn;
  }

  get userServicesInfo() {
    return this.servicesUser;
  }

  get servicesList() {
    return this.services;
  }
}

export default getModule(Auth);
