import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { EAgreementStatus, IAgreement, IAgreementFile, IAgreementStatus } from "@/store/agreement/interfaces";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "Agreement" })
class AgreementStore extends VuexModule {
  private _agreementList = {} as IAgreement;
  private _agreementFile = {} as IAgreementFile;

  @Mutation
  public SET_AGREEMENT_LIST(value: IAgreement): void {
    this._agreementList = value;
  }

  @Mutation
  public SET_AGREEMENT_FILE(value: IAgreementFile): void {
    this._agreementFile = value;
  }

  @Action({ rawError: true })
  public async getAgreementFile(id: string) {
    const response: AxiosResponse<IAgreementFile> = await instanceBilling.get(
      `agreement/${id}/file`
    );
    
    this.SET_AGREEMENT_FILE(response?.data);
    return response?.data; 
  }

  @Action({ rawError: true })
  public async createAgreement(options: {
    orgId: number;
    accountId: number,
    innerOperationRequired: boolean;
  } ) {
    const response: AxiosResponse = await instanceBilling.post(
      "agreement", options
    );

    return response
  }

  @Action({ rawError: true })
  public async getAgreementList(options?: IPagination & ISortSearch) {
    const response: AxiosResponse<IAgreement> = await instanceBilling.get(
      "agreement", { params: options }
    );
    this.SET_AGREEMENT_LIST(response.data);
  }

  @Action({ rawError: true })
  public async deleteAgreement(id: string) {
    const response: AxiosResponse = await instanceBilling.delete(
      `agreement/${id}`
    );
  }

  get agreementList() {
    return this._agreementList;
  }

  get agreementFile() {
    return this._agreementFile;
  }

  get agreementStatuses(): IAgreementStatus[] {
    return [
      {
        eng: EAgreementStatus.NEW,
        name: "Новый",
        type: ""
      },
      {
        eng: EAgreementStatus.WAITING,
        name: "В ожидании",
        type: "warning"
      },
      {
        eng: EAgreementStatus.DONE,
        name: "Заключен",
        type: "success"
      },
      {
        eng: EAgreementStatus.INACTIVE,
        name: "Неактивен",
        type: "danger"
      },
    ];
  }

}

export default getModule(AgreementStore);
