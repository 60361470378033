import Vue from "vue";
import VueCookies from "vue-cookies-ts";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/plugins/element";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap-icons/font/bootstrap-icons.css'
import RootStore from "@/store/root";
import "@/filters";
import AccessPlugin from "@/plugins/access";
import DictionaryPlugin from "@/plugins/dictionary";

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(AccessPlugin);
Vue.use(DictionaryPlugin);

RootStore.initApp().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
