import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { instanceAms } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import {
  IRight,
  IRightRole,
  IRole,
  IRoleDependency,
} from "@/store/roles/interfaces";

@Module({ dynamic: true, store, name: "Roles" })
class Roles extends VuexModule {
  @Action({ rawError: true })
  public async fetchServiceRoles(options: {
    serviceId: number;
    filters?: { [key: string]: any };
  }) {
    const response: AxiosResponse<IRole[]> = await instanceAms.get(
      `/roles/${options.serviceId}`,
      { params: options.filters }
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async fetchRoleRights(options: {
    role: IRole;
    filters?: { [key: string]: any };
  }) {
    const response: AxiosResponse<IRightRole[]> = await instanceAms.get(
      `/rights/role/${options.role.key}/${options.role.serviceId}`,
      { params: options.filters }
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async fetchRoleDependency(options: {
    role: IRole;
    filters?: { [key: string]: any };
  }) {
    const response: AxiosResponse<{ result: IRoleDependency[] }> =
      await instanceAms.get(
        `/roleDependencies/${options.role.key}/${options.role.serviceId}`,
        { params: options.filters }
      );

    return response.data;
  }

  @Action({ rawError: true })
  public async removeRight(options: { key: string; serviceId: number }) {
    const response: AxiosResponse<any> = await instanceAms.delete(
      `/rights/${options.key}/${options.serviceId}`
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async removeRole(options: { key: string; serviceId: number }) {
    const response: AxiosResponse<any> = await instanceAms.delete(
      `/roles/${options.key}/${options.serviceId}`
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async fetchServiceRights(options: {
    serviceId: number;
    filters?: { [key: string]: any };
  }) {
    const response: AxiosResponse<IRight[]> = await instanceAms.get(
      `/rights/${options.serviceId}`,
      { params: options.filters }
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async batchCreateRights(form: {
    serviceId: number;
    rights: Partial<IRight>[];
  }) {
    const response: AxiosResponse<IRight[]> = await instanceAms.post(
      `/rights`,
      form
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async updateRoleRights(form: {
    serviceId: number;
    roleKey: string;
    rights: Partial<IRight>[];
  }) {
    const response: AxiosResponse<IRight[]> = await instanceAms.put(
      `/rights/role`,
      form
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async batchCreateRoles(form: {
    serviceId: number;
    roles: Partial<IRole>[];
  }) {
    const response: AxiosResponse<IRight[]> = await instanceAms.post(
      `/roles`,
      form
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async updateRight(body: {
    serviceId: number;
    key: string | null;
    name: string | null;
    description?: string | null;
  }) {
    const response: AxiosResponse<{ message: string; result: IRight[] }> =
      await instanceAms.put(`/rights`, body);

    return response.data;
  }

  @Action({ rawError: true })
  public async updateRole(body: {
    serviceId: number;
    key: string | null;
    name: string | null;
    description?: string | null;
  }) {
    const response: AxiosResponse<{ message: string; result: IRole[] }> =
      await instanceAms.put(`/roles`, body);

    return response.data;
  }

  @Action({ rawError: true })
  public async createRole(body: {
    serviceId: number;
    key: string | null;
    name: string | null;
    description?: string | null;
  }) {
    const response: AxiosResponse<{ message: string; result: IRole[] }> =
      await instanceAms.post(`/roles`, body);

    return response.data;
  }

  @Action({ rawError: true })
  public async createRight(body: {
    serviceId: number;
    key: string | null;
    name: string | null;
    description?: string | null;
  }) {
    const response: AxiosResponse<{ message: string; result: IRight[] }> =
      await instanceAms.post(`/rights`, body);

    return response.data;
  }

  @Action({ rawError: true })
  public async createUserRights(body: {
    accountMemberId: number;
    roles: string[];
    rights: string[];
  }) {
    const response: AxiosResponse<{ message: string; result: IRight[] }> =
      await instanceAms.post(`/userRights`, body);

    return response.data;
  }

  @Action({ rawError: true })
  public async userScopes(body: [{
    memberId: number,
    serviceId: number,
    scope: {
      cashbox?: number[],
      organization?: number[]
    }
  }] ) {
    const response = await instanceAms.post(`/userScopes`, body);

    return response.data;
  }
}

export default getModule(Roles);
