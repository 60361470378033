

































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import AccountStore from "@/store/account";
import RolesStore from "@/store/roles";
import { IService } from "@/store/root/interfaces";
import TranslateMixin from "@/mixins/TranslateMixin";
import CashboxStore from "@/store/cashbox/index";
import AccontMembersStore from "@/store/accontMembers";
import OrganizationStore from "@/store/organization";
import {
  IRight,
  IRole,
} from "@/store/roles/interfaces";
import { IAccountMember, IGetUser } from "@/store/account/interfaces";
import { Notification } from "element-ui";

const defaultUser = JSON.stringify({
  id: null,
  mobile: "",
  lastName: "", 
  firstName: "",
  middleName: null,
  email: null,
  inn: null,
  comment: "",
  password: "",
  confirmPassword: "",
  accountId: null,
  serviceIds: [],
  rolesRights: {},
});

const defaultFiltersCashbox = JSON.stringify({
  page: 1,
  limit: 10,
  organizationId: [],
  search: "",
});

const defaultFiltersOrg = JSON.stringify({
  page: 1,
  limit: 10,
  filter: {
    sub: [],
  },
  like: {
    search: "",
  }
});

@Component({
  props: {
    propsUser: { type: Object },
  },
})
export default class UpdateUserModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  activeNames = []
  show = false;
  loading = false
  owner = false
  readonlyPassword = true
  serviceIds: number[] = [] 
  selects: any = {};
  user = {} as IGetUser;
  serviceList = {} as IService[]
  formUser = JSON.parse(defaultUser);
  form = {
    rights: [],
    roles: [],
  };

  scopeService = {} as IService
  scopeServiceId: any = []

  filtersCashbox = JSON.parse(defaultFiltersCashbox);
  multipleSelectionCashbox = [] as any
  filtersOrg = JSON.parse(defaultFiltersOrg);
  multipleSelectionOrg = [] as any

  open() {
    this.owner = false
    this.show = true;
  }

  async handleOpen() {
    this.init();
  }

  async init() {
    this.loading = true

    this.serviceList = this.$dictionary({key:'services', type: 'list'}) as IService[]
    await this.getUserById()
    await this.initFormUser()
    this.owner ? await this.changeService(this.serviceIds)
      : await this.changeService(this.formUser.serviceIds)
    
    this.filtersOrg.filter.sub[0] = this.$props.propsUser?.account?.id
    await this.getOrgList()  
    this.multipleSelectionOrg = this.user.scope[0]?.scope.organization || []

    this.filtersCashbox.organizationId = this.orgList.data?.map(org => org.id)
    await this.getCashboxList()  
    this.multipleSelectionCashbox = this.user.scope[0]?.scope.cashbox || []

    this.loading = false
  }

  async getUserById(){
    this.user = await AccountStore.getUserById(this.$props.propsUser.userId);

    if(Object.keys(this.user.otherRights).length === 0) this.owner = true
  }

  async initFormUser(){
    this.formUser = {
      ...this.user.user,   
      accountId:this.$props.propsUser.accountId
    }
    
    if(!this.owner){
      this.formUser.rolesRights = this.user.otherRights
      this.formUser.serviceIds = Object.keys(this.user.otherRights).map(parseFloat)
    } else{
      this.form.roles = this.user.roles
      this.form.rights = this.user.rights
      this.serviceIds = this.serviceList.map(service => service.id)
    }
  }  

  async deleteService(){
    if (this.formUser.serviceIds){
      this.serviceList.forEach((service) => {  
        if(!this.formUser.serviceIds.includes(service.id) && this.formUser.rolesRights[service.id]){
          this.$delete(this.formUser.rolesRights, service.id)
        }
      })
    }
  }
  
  async changeService(serviceIds: number[]) {
    if (serviceIds) {
      serviceIds.forEach( async (service) => {
        const fetchServiceRoles = await RolesStore.fetchServiceRoles({
          serviceId: service,
        });
        const fetchServiceRights = await RolesStore.fetchServiceRights({
          serviceId: service,
        });
        this.$set(this.selects, service, { fetchServiceRoles, fetchServiceRights });

        const roles = [] as IRole[] 
        const rights = [] as IRight[]
        
        if(this.formUser.rolesRights && !this.formUser.rolesRights[service]) 
          this.$set(this.formUser.rolesRights, service, { roles, rights });
      })
    }
  }

  async saveUserInfo() {
    const response = await AccountStore.editUser({
      id: this.formUser.id,
      lastName: this.formUser.lastName, 
      firstName: this.formUser.firstName,
      middleName: this.formUser.middleName ? this.formUser.middleName : null,
      email: this.formUser.email ? this.formUser.email : null,
      inn: this.formUser.inn ? this.formUser.inn : null,
      comment: this.formUser.comment,
    }); 
    if(response) this.init()
  }

  async savePassword() {
    if (this.formUser.password !== this.formUser.confirmPassword) {
      Notification.error({
        title: "Ошибка",
        message: "Пароли не совпадают",
      });
      return
    }
    if (!this.formUser.password) {
      const response = await AccountStore.editUser({
        id: this.formUser.id,
        mobile: this.formUser.mobile,
      });
      if(response) this.init()
    } 
    else {
      const response = await AccountStore.editUser({
        id: this.formUser.id,
        mobile: this.formUser.mobile,
        password: this.formUser.password,
        confirmPassword: this.formUser.confirmPassword
      });
      if(response) this.init()
    }
  }

  async saveRolesRights() {
    if(this.owner) {
      const response = await AccountStore.editUser({
        id: this.formUser.id,
        accountId: this.formUser.accountId,
        roles: this.form.roles,
        rights: this.form.rights,
      });
      if(response) this.init()
    } 
    else {
      this.deleteService()
      const response = await AccountStore.editUser({
        id: this.formUser.id,
        serviceIds: this.formUser.serviceIds,
        rolesRights: this.formUser.rolesRights,
        accountId: this.formUser.accountId,
      });
      if(response) this.init()
    }
  }

  async saveScope() {
    await RolesStore.userScopes([{
      memberId: await this.getMemberId(),
      serviceId: Number(this.scopeServiceId),
      scope: {
        cashbox: this.multipleSelectionCashbox,
        organization: this.multipleSelectionOrg,
      }
    }])
    await this.init()
  }

  async getMemberId() {
    const members = await AccontMembersStore.getServiceMembers({
      serviceId: Number(this.scopeServiceId),
      filters: {
        search: this.$props.propsUser.user.lastName
      }
    });
    return members.data.find((member: IAccountMember) => member.userId == this.$props.propsUser.userId)?.id
  }

  async getCashboxList() {
    await CashboxStore.getCashboxesList({
      filter: this.filtersCashbox,
    });
  }

  async getOrgList() {
    await OrganizationStore.getOrganizations({
      ...this.filtersOrg,
    });
  }

  pageSizeChangeCashbox(limit: number) {
    this.filtersCashbox.limit = limit;
    this.getCashboxList();
  }

  changePageCashbox(page: number) {
    this.filtersCashbox.page = page;
    this.getCashboxList();
  }

  pageSizeChangeOrg(limit: number) {
    this.filtersOrg.limit = limit;
    this.getOrgList();
  }

  changePageOrg(page: number) {
    this.filtersOrg.page = page;
    this.getOrgList();
  }

  handleClose() {
    this.activeNames = []
    this.show = false
    this.owner = false
    this.refreshForm()
    this.$emit("submit")
  }

  refreshForm() {
    this.formUser = JSON.parse(defaultUser);
  }

  get cashboxesList() {
    return CashboxStore.cashboxList;
  }

  get orgList() {
    return OrganizationStore.organizations.result;
  }

}
