import { DirectionTable, IPagination } from "@/interfaces";

export interface ITransportListResponse extends IPagination {
  data: ITransportLicense[];
}

export interface ILicenseTransportListResponse {
  billingLicenses: ILicenseBilling[];
  licenses: ITransportListResponse,
}

export interface ILicenseBilling {
  accountId: number,
  activeFrom: string,
  activeTo: string,
  createdAt: string,
  deletedAt: string,
  id: string,
  licenseId: string,
  name: string,
  service: string,
  status: string,
  tariffId: string,
  updatedAt: string,
}

export interface ITransportLicense {
  accountId: number;
  activeFrom: string;
  activeTo: string;
  comment: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  isBindedToDevice: boolean;
  licenseToken: string;
  name: string;
  secret: string | null;
  status: ETransportLicenseStatus;
  updatedAt: string;
}

export interface ITransportRegister {
  accountId: number;
  comment: string;
  confirmation_code: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  status: ETransportRegisterStatus;
  updatedAt: string;
  userId: number;
}

export interface ITransportRegisterResponse extends IPagination {
  data: ITransportRegister[];
}

export interface ITransportLicenseListSort {
  createdAt: DirectionTable;
  name: DirectionTable,
  accountId: DirectionTable,
  isBindedToDevice: DirectionTable,
}

export enum ETransportLicenseStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PAUSED = "paused",
  EXPIRED = "expired"
}

export enum ETransportRegisterStatus {
  NEW = "new",
  DONE = "done",
}

export interface ITransportLicenseStatus {
  id: ETransportLicenseStatus;
  name: string;
  type: string
}

export interface ITransportRegisterStatus {
  id: ETransportRegisterStatus;
  name: string;
}
