import AuthStore from "@/store/auth";
import config from "@/config";
import axios from "axios";
import qs from "qs";
import Vue from "vue";
import { Notification } from "element-ui";
import router from "@/router";

export const instanceLayer = axios.create({
  baseURL: config.layer.host,
  paramsSerializer,
});

export const instanceBackend = axios.create({
  baseURL: config.paybox.host,
  paramsSerializer,
});

export const instanceAms = axios.create({
  baseURL: config.ams.host,
  paramsSerializer,
});

export const instanceQR = axios.create({
  baseURL: config.qr.host,
  paramsSerializer,
});

export const instanceTransport = axios.create({
  baseURL: config.transport.host,
  paramsSerializer,
});

export const instanceBilling = axios.create({
  baseURL: config.billing.host,
  paramsSerializer,
});

instanceAms.interceptors.request.use(requestHandler);
instanceAms.interceptors.response.use(responseHandler, errorHandler);

instanceBackend.interceptors.request.use(requestHandler);
instanceBackend.interceptors.response.use(responseHandler, errorHandler);

instanceQR.interceptors.request.use(requestHandler);
instanceQR.interceptors.response.use(responseHandler, errorHandler);

instanceTransport.interceptors.request.use(requestHandler);
instanceTransport.interceptors.response.use(responseHandler, errorHandler);

instanceBilling.interceptors.request.use(requestHandler);
instanceBilling.interceptors.response.use(responseHandler, errorHandler);

function paramsSerializer(params: any) {
  return qs.stringify(params, { arrayFormat: "repeat" });
}

function requestHandler(request: any) {
  request.headers = {
    authorization: `Bearer ${Vue.cookies.get("accessToken")}`,
  };
  return request;
}

function responseHandler(response: any) {
  if (response.data && response.data.message) {
    Notification.success({
      title: "Успешно",
      message: response.data.message,
    });
  }

  return response;
}

function errorHandler(error: any) {
  const status = error?.response?.status;

  const message =
    error.response &&
    error.response.data &&
    (error.response.data.message || error.response.data.description || error.response.data.error);

  if (status === 401) {
    AuthStore.LOGOUT();
    router.push("/login");
  } else {
    Notification.error({
      title: "Ошибка",
      message: message || "Неизвестная ошибка",
    });
  }
  
  return error
}
