








































































import moment from "moment";
import { Component, Mixins } from "vue-property-decorator";
import TransportStore from "@/store/transport";
import TranslateMixin from "@/mixins/TranslateMixin";

@Component({
  name: "qrRegister",
  mixins: [TranslateMixin],
})
export default class qrRegister extends Mixins<TranslateMixin>(
  TranslateMixin
) 
{
  loading = false;
  filters = {
    page: 1,
    limit: 10,
    dates: [] as string[],
  };

  mounted() {
    this.init();
  }

  async init() {
    this.loading = true;
    await TransportStore.getTransportRegisterList(this.filters);
    this.loading = false;
  }

  async pageSizeChange(size: number) {
    this.filters.limit = size;
    await this.init();
  }

  async changePage(page: number) {
    this.filters.page = page;
    await this.init();
  }

  async changeDateRange() {
    await this.init();
  }

  pickDateRange(dates: { maxDate: Date | string; minDate: Date | string }) {
    if (dates.minDate)
      dates.minDate = moment(dates.minDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    if (dates.maxDate)
      dates.maxDate = moment(dates.maxDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

    this.filters.dates = [dates.minDate, dates.maxDate];

    if (dates.minDate && dates.maxDate) {
      this.init();
    }
  }

  get transportRegiserList() {
    return TransportStore.transportRegisterList;
  }
}
