





























































import { Notification } from "element-ui";
import { Component, Mixins } from "vue-mixin-decorator";
import { Prop } from "vue-property-decorator";
import BidStore from "@/store/bid";
import CashboxStore from "@/store/cashbox";
import TranslateMixin from "@/mixins/TranslateMixin";
import CashBoxModal from "@/components/modals/CashBoxModal.vue";
import FiscalMemoryModal from "@/views/bids/modals/FiscalMemoryModal.vue";
import { ICashBox } from "@/store/cashbox/interfaces";

@Component({
  components: { CashBoxModal, FiscalMemoryModal },
  mixins: [TranslateMixin],
})
export default class BidCashBoxesModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  $refs!: {
    CashBoxModal: CashBoxModal;
    FiscalMemoryModal: FiscalMemoryModal;
  };

  @Prop({ required: true }) readonly bidId!: number;

  selecedCashBox = {} as ICashBox;

  show = false;

  load = false;

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  async handleOpen() {
    await this.init();
  }

  async getBid() {
    await BidStore.getBid(this.bidId);
  }

  async init() {
    this.load = true;
    this.getBid();
    this.load = false;
  }

  async handleActions(event: any) {
    this.selecedCashBox = event.row;

    if (event.name === "EditCashbox") {
      this.$refs.CashBoxModal.open();
    }

    if (event.name === "FiscalMemoryModal") {
      this.$refs.FiscalMemoryModal.open();
    }

    if (event.name === "GetHardware") {
      this.load = true;
      await CashboxStore.getHardware(this.selecedCashBox.id);
      Notification.success({
        title: "Успешно",
        message: "Запрос данных отправлен на кассу",
      });
      setTimeout(() => {
        this.getBid();
        this.load = false;
      }, 2000);
    }
  }

  get bid() {
    return BidStore.bid;
  }
}
