

























































































































import { Component, Mixins } from "vue-mixin-decorator";
import UserModal from "@/views/users/modals/UserModal.vue";
import CreateUserModal from "@/views/users/modals/CreateUserModal.vue";
import AccountStore from "@/store/account";
import RootStore from "@/store/root";
import TranslateMixin from "@/mixins/TranslateMixin";

@Component({
  name: "UsersPage",
  components: { UserModal, CreateUserModal },
  mixins: [TranslateMixin],
})
export default class UsersPage extends Mixins<TranslateMixin>(TranslateMixin) {
  $refs!: {
    UserModal: UserModal;
    CreateUserModal: CreateUserModal;
  };
  selectedUser = {};
  loading = false;
  filters = {
    page: 1,
    limit: 10,
    search: "",
    accountId: undefined,
    serviceId: undefined,
  };
  additionalColums = {
    roleKey: false,
    rightKey: false,
  };

  mounted() {
    this.init();
  }

  async getUsersList() {
    this.loading = true;
    await AccountStore.getUsersList(this.filters);
    this.loading = false;
  }

  async init() {
    await this.getUsersList();
  }

  async pageSizeChange(size: number) {
    this.filters.limit = size;
    await this.getUsersList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    await this.getUsersList();
  }

  handleActions(event: any) {
    this.selectedUser = event.row;

    if (event.name === "UserModal") this.$refs.UserModal.open();
  }

  openCreateUserModal() {
    const modal: any = this.$refs["CreateUserModal"];

    modal.open();
  }

  get usersList() {
    return AccountStore.usersList;
  }

  get accountsList() {
    return AccountStore.accountsList;
  }
}
