import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { instanceAms } from "@/plugins/axios";
import RootStore from "@/store/root";
import { IPagination, IPaginationParams, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "AccontMembers" })
class AccontMembers extends VuexModule {
  @Action({ rawError: true })
  public async getServiceMembers(options: {
    serviceId: number;
    filters?: IPaginationParams & ISortSearch;
  }) {
    const response: any = await instanceAms.get(
      `/accountMembers/${options.serviceId}`,
      { params: options.filters }
    );

    return response.data;
  }

  @Action({ rawError: true })
  public async getOwnersList() {
    const response: any = await instanceAms.get(`/accountMembers/owners/list`);

    if (response && response.data) {
      RootStore.SET_DICTIONARIES({
        data: response.data.data,
        field: "owners",
      });
    }

    return response.data;
  }
}

export default getModule(AccontMembers);
