







































































































































































































































































import { Component, Mixins } from "vue-mixin-decorator";
import { Prop } from "vue-property-decorator";
import CashBoxStore from "@/store/cashbox";
import RootStore from "@/store/root";
import TranslateMixin from "@/mixins/TranslateMixin";
import { ICashboxExtendedData } from "@/store/cashbox/interfaces";

const initForm: Partial<ICashboxExtendedData> = {
  fnData: {
    serial: "",
    activationDate: "",
    documentCount: "",
    expireDate: "",
    ffdVersion: "",
    warnings: {
      memoryOverflow: false,
      needReplacement: false,
      ofdTimeout: false,
      resourceExhausted: false,
      criticalError: false
    }
  },
  ofd: {
    dns: "",
    host: "",
    name: "",
    port: -1,
    vatin: ""
  },
  ofdInteractionInfo: {
    errors: {
      fn: {
        code: -1,
        description: ""
      },
      ofd: {
        code: -1,
        description: ""
      },
      network: {
        code: -1,
        description: ""
      },
      lastSuccessConnectionDateTime: ""
    },
    status: {
      notSentCount: -1,
      notSentFirstDocDateTime: "",
      notSentFirstDocNumber: -1
    }
  },
  device: {
    model: "",
    serialNumber: "",
    regNumber: "",
    configurationVersion: "",
    firmwareVersion: "",
    ffdVersion: "",
    paymentAddress: "",
    organization: {
      address: "",
      agents: [],
      email: "",
      name: "",
      taxationTypes: [],
      vatin: ""
    },
    sno: "",
    settings: {
      marking: false,
      excise: false,
      gambling: false,
      insurance: false,
      internet: false,
      lottery: false,
      pawnShop: false,
    },
    connection: {
      interface: "",
      address: "",
      os: ""
    },
    shift: {
      shiftStatus: {
        expiredTime: "",
        number: -1,
        state: ""
      }
    },
    status: {
      shift: "",
      fiscal: false,
      blocked: false,
      fnFiscal: false,
      fnPresent: false,
      coverOpened: false,
      paperPresent: false,
      currentDateTime: "",
      cashDrawerOpened: false
    }
  }
};

@Component({
  mixins: [TranslateMixin],
})
export default class CashBoxExtendedModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  @Prop() readonly cashboxId!: -1;
  @Prop({ default: false }) readonly nested!: false;

  show = false;

  load = false;

  form = initForm;

  activeSections = [ 'ofd', 'ofdInteractionInfo' ]

  open() {
    this.show = true;
  }

  handleClose() {
    this.show = false;
    this.form = initForm;
  }

  async handleOpen() {
    this.init();
  }

  async init() {
      this.load = true;
      const response = await CashBoxStore.getCashbox(this.cashboxId);
      this.form = { ...this.form, ...this.cashbox?.extendedData }
      if(!response) {
        this.show = false;
        return
      }
      this.load = false;
  }

  get cashbox() {
    return CashBoxStore.cashbox;
  }

}
