
































import { Component, Vue } from "vue-property-decorator";
import RootStore from "@/store/root";
import RolesStore from "@/store/roles";
import { IService } from "@/store/root/interfaces";

@Component({
  props: {
    user: { type: Object },
  },
})
export default class UserRightsModal extends Vue {
  show = false;
  loading = false;

  form = {
    rights: [],
    roles: [],
  };

  selects: any = {};

  open() {
    this.show = true;
  }

  handleOpen() {
    this.init();
  }

  async init() {
    this.loading = true;
    if (this.serviceList) {
      for (const service of this.serviceList) {
        const roles = await RolesStore.fetchServiceRoles({
          serviceId: service.id,
        });
        const rights = await RolesStore.fetchServiceRights({
          serviceId: service.id,
        });
        this.$set(this.selects, service.id, { roles, rights });
      }
      this.form.rights = this.$props.user.rights
        .map((r: any) => r.rightKey)
        .filter(Boolean);
      this.form.roles = this.$props.user.rights
        .map((r: any) => r.roleKey)
        .filter(Boolean);
    }

    this.loading = false;
  }

  async submit() {
    await RolesStore.createUserRights({
      accountMemberId: this.$props.user.id,
      ...this.form,
    });
    this.$emit("submit");
    this.show = false;
  }

  get serviceList() {
    const list = this.$dictionary({key:'services', type: 'list'}) as IService[]
    
    if (!this.$props.user) return null;
    if (!this.$props.user.owner) {
      return list.filter(
        (r) => r.id === this.$props.user.serviceId
      );
    }
    return list;
  }
}
