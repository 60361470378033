




























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { SystemEventModule } from "@/store/systemEvent/index";
import { ISystemEventsList } from "@/store/systemEvent/interface";
import { allOwnerTypes } from "@/utils/consts";

@Component
export default class SystemEventsSection extends Vue {
  systemEventsList: ISystemEventsList = {} as ISystemEventsList;
  loading = true;
  tablePage = 1;

  allOwnerTypes = allOwnerTypes;
  selectedOwnerTypes: {
    name: string;
    code: number;
  }[] = [];
  searchBar = "";
  caseSensetive = false;

  sortParams: { [key: string]: any } = {
    id: "ASC",
    ownerType: "",
    ownerId: "",
    createdAt: "",
    code: "",
  };
  filterParams: {
    ownerType: number[];
  } = {
    ownerType: [],
  };
  like = {
    search: "",
    caseSensitive: false,
  };
  rangeParams: {
    createdAt: {
      from: string;
      to: string;
    };
  } = {
    createdAt: {
      from: "",
      to: "",
    },
  };

  systemEventsDates: any = [];

  async mounted() {
    this.getSystemEvents();
  }

  async getSystemEvents() {
    this.loading = true;
    await SystemEventModule.getSystemEvents({
      page: this.tablePage,
      sort: this.sortParams,
      filter: this.filterParams,
      range: this.rangeParams,
      like: this.like,
    });
    this.systemEventsList = SystemEventModule.systemEventsList;
    this.loading = false;
  }

  onPage(event: any) {
    this.tablePage = event.page + 1;
    this.getSystemEvents();
  }

  sortByKey(myKey: string) {
    Object.keys(this.sortParams).forEach((key) => {
      if (key === myKey) {
        switch (this.sortParams[myKey]) {
          case "":
            this.sortParams[myKey] = "ASC";
            break;
          case "ASC":
            this.sortParams[myKey] = "DESC";
            break;
          case "DESC":
            this.sortParams[myKey] = "";
            break;
        }
      } else {
        this.sortParams[key] = "";
      }
    });
    this.loading = true;
    this.getSystemEvents();
  }

  filterByOwnerTypes() {
    this.filterParams.ownerType = this.selectedOwnerTypes.map(
      (status) => status.code
    );
    this.loading = true;
    this.getSystemEvents();
  }

  clearDateRange() {
    this.systemEventsDates = [];
  }

  @Watch("systemEventsDates")
  onChildChanged() {
    this.rangeParams = {
      createdAt: {
        from: this.systemEventsDates && this.systemEventsDates[0],
        to: this.systemEventsDates && this.systemEventsDates[1],
      },
    };
    this.loading = true;
    this.getSystemEvents();
  }

  @Watch("searchBar")
  search() {
    this.like = {
      search: this.searchBar,
      caseSensitive: this.caseSensetive,
    };
    this.loading = true;
    this.getSystemEvents();
  }
}
