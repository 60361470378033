








































import { Component, Vue, Watch } from "vue-property-decorator";
import RolesStore from "@/store/roles";
import { IRight } from "@/store/roles/interfaces";
import { updateQuery } from "@/utils";
import RightsModal from "@/views/roleSettings/modals/RightsModal.vue";

@Component({
  props: {
    serviceId: { type: Number, required: true },
  },
  components: { RightsModal },
})
export default class Rights extends Vue {
  rights: IRight[] = [];
  editableRow = null;
  loading = false;
  filters = {
    search: this.$route.query.search || "",
  };

  handleActions(event: any) {
    if (event.name === "RemoveRight") {
      this.$confirm(
        `Если удалить право без обратной совместимости, то все роуты завязанные на этом праве перестанут работать. Удалить право ${event.row.key}?`,
        "Осторожно",
        {
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await RolesStore.removeRight({
          serviceId: this.$props.serviceId,
          key: event.row.key,
        });
        this.fetchData();
      });
    }
    if (event.name === "EditRight") {
      this.editableRow = event.row;
    }
  }

  async updateRight() {
    await RolesStore.updateRight(this.editableRow as any);
    this.editableRow = null;
    this.fetchData();
  }

  openModal(name: string) {
    const modal: any = this.$refs[name];

    modal.open();
  }

  mounted() {
    this.fetchData();
  }

  @Watch("serviceId")
  serviceChange() {
    this.fetchData();
  }

  async changeSearch() {
    updateQuery(this, this.filters);

    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    this.rights = await RolesStore.fetchServiceRights({
      serviceId: this.$props.serviceId,
      filters: this.filters,
    });
    this.loading = false;
  }
}
