



















import { Component, Vue } from "vue-property-decorator";
import WorkerStore from "@/store/worker";

@Component({
  props: {
    workerId: { type: Number },
  },
})
export default class CreateWorkerModal extends Vue {
  show = false;

  form = {
    host: "",
    name: "",
    password: "",
  };

  open() {
    this.show = true;
  }

  handleClose() {
    this.refreshForm();
  }

  async handleOpen() {
    return;
  }

  refreshForm() {
    this.form = {
      host: "",
      name: "",
      password: "",
    };
  }

  async submit() {
    await WorkerStore.createWorker(this.form);
    this.refreshForm();
    this.show = false;
    this.$emit("reload");
  }
}
