import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceTransport } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import {
  ITransportLicenseStatus,
  ITransportLicense,
  ITransportRegisterResponse,
  ITransportRegisterStatus,
  ETransportLicenseStatus,
  ETransportRegisterStatus,
  ILicenseTransportListResponse,
} from "@/store/transport/interfaces";

@Module({ dynamic: true, store, name: "Transport" })
class TransportStore extends VuexModule {
  public _transportLicenceList: ILicenseTransportListResponse =
    {} as ILicenseTransportListResponse;
  public _transportRegisterList: ITransportRegisterResponse =
    {} as ITransportRegisterResponse;
  public _transportLicence: ITransportLicense = {} as ITransportLicense;

  @Mutation
  public SET_TRANSPORT_LICENSE_LIST(data: ILicenseTransportListResponse): void {
    this._transportLicenceList = data;
  }

  @Mutation
  public SET_TRANSPORT_REGISTER_LIST(data: ITransportRegisterResponse): void {
    this._transportRegisterList = data;
  }

  @Mutation
  public SET_TRANSPORT_LICENCE(data: ITransportLicense): void {
    this._transportLicence = data;
  }

  @Action({ rawError: true })
  public async getTransportLicenceList(filters: {
    page: number;
    limit: number;
    dates?: string[];
    sort?: string[];
  }) {
    const response: AxiosResponse<ILicenseTransportListResponse> =
      await instanceTransport.get("/api/licenses", { params: filters });

    this.SET_TRANSPORT_LICENSE_LIST(response.data);

    return response.data;
  }

  @Action({ rawError: true })
  public async getTransportRegisterList(filters: {
    page: number;
    limit: number;
    dates: string[];
  }) {
    const response: AxiosResponse<ITransportRegisterResponse> =
      await instanceTransport.get("/api/regRequests", { params: filters });

    this.SET_TRANSPORT_REGISTER_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async getTransportLicence(licenceId: string) {
    const response: AxiosResponse<ITransportLicense> =
      await instanceTransport.get(`/api/licenses/${licenceId}`);

    this.SET_TRANSPORT_LICENCE(response.data);

    return response.data;
  }

  @Action({ rawError: true })
  public async updateTransportLicence(options: {
    licenseId: string;
    form: Partial<ITransportLicense>;
  }) {
    const response: AxiosResponse<ILicenseTransportListResponse> =
      await instanceTransport.put(
        `/api/licenses/${options.licenseId}`,
        options.form
      );

    this.SET_TRANSPORT_LICENSE_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async unbindDevice(licenseId: string) {
    await instanceTransport.put(`/api/licenses/${licenseId}/unbind`);
  }

  get transportLicenceList() {
    return this._transportLicenceList;
  }

  get transportRegisterList() {
    return this._transportRegisterList;
  }

  get transportLicence() {
    return this._transportLicence;
  }

  get transportRegisterStatuses(): ITransportRegisterStatus[] {
    return [
      {
        id: ETransportRegisterStatus.NEW,
        name: "Новая",
      },
      {
        id: ETransportRegisterStatus.DONE,
        name: "Активна",
      },
    ];
  }

  get transportLicenseStatuses(): ITransportLicenseStatus[] {
    return [
      {
        id: ETransportLicenseStatus.ACTIVE,
        name: "Активна",
        type: 'success'
      },
      {
        id: ETransportLicenseStatus.INACTIVE,
        name: "Неактивна",
        type: 'info'
      },
      {
        id: ETransportLicenseStatus.PAUSED,
        name: "Приостановлена",
        type: 'warning'
      },
      {
        id: ETransportLicenseStatus.EXPIRED,
        name: "Истекла",
        type: 'danger'
      },
    ];
  }
}

export default getModule(TransportStore);
