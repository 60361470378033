





































































import { Component, Mixins } from "vue-mixin-decorator";
import BillingEventsStore from "@/store/billingEvents";
import TranslateMixin from "@/mixins/TranslateMixin";
import { DirectionTable } from "@/interfaces";
import { ESubjectType, ISystemEventsListSort } from "@/store/billingEvents/interfaces";
import { formatRangeDatesUtc } from "@/utils";

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
});


@Component({
  components: {},
  mixins: [TranslateMixin],
})
export default class BillingEvents extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  loading = false;
  showFilters = false;

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  selectedSubjectType = [] as ESubjectType[];

  mounted() {
    this.init();
  }

  async init() {
    await this.getEventsList()
  }

  async getEventsList() {
    this.loading = true;
    await BillingEventsStore.getBillingEventsList({
      ...this.filters,
      sort: this.sorts,
      subjectType: this.selectedSubjectType
    });
    this.loading = false;
    this.closeFilters();
  }

  changeDate(dates: string[]) {
    this.filters.dates = formatRangeDatesUtc(dates);
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getEventsList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getEventsList();
  }

  async changeSort(e: {
    column: string;
    prop: keyof ISystemEventsListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";
    const sortMap: {
      [key: string]: keyof ISystemEventsListSort
    } = {
      createdAt: "createdAt",
    };
    if (!sortMap[e.prop]) return;
    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: DirectionTable = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }
    this.getEventsList();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getEventsList();
  }

  get eventsList() {
    return BillingEventsStore.billingEventsList;
  }
}
