const config = {
  tokens: {
    access: process.env.VUE_APP_COOKIE_ACCESS_TOKEN || "accessToken",
  },
  layer: {
    host: process.env.VUE_APP_LAYER_HOST || "http://localhost:4002",
  },
  paybox: {
    host: process.env.VUE_APP_PAYBOX_HOST || "http://localhost:4000/api",
  },
  ams: {
    host: process.env.VUE_APP_AMS_HOST || "http://localhost:3500",
  },
  qr: {
    host: process.env.VUE_APP_QR_HOST || "http://localhost:4100",
  },
  transport: {
    host: process.env.VUE_APP_TRANSPORT_HOST || "http://localhost:4200",
  },
  billing: {
    host: process.env.VUE_APP_BILLING_HOST || "http://localhost:4300",
  },
  linkCheck: {
    host: process.env.VUE_APP_LINK_CHECK || "http://localhost:3000",
  },
};

export default config;
