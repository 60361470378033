
























































































import { Component, Mixins } from "vue-property-decorator";
import AccountStore from "@/store/account";
import AuthStore from "@/store/auth";
import RolesStore from "@/store/roles";
import { IService } from "@/store/root/interfaces";
import TranslateMixin from "@/mixins/TranslateMixin";

const defaultUser = JSON.stringify({
  mobile: "",
  lastName: "", 
  firstName: "",
  middleName: null,
  email: null,
  inn: null,
  comment: "",
  roles: null,
  password: "",
  confirmPassword: "",

  accountId: null,
  serviceIds: [2],
  rolesRights: {},
});

@Component({
  props: {
    workerId: { type: Number },
  },
})
export default class CreateUserModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  show = false;
  selects: any = {};
  formUser = JSON.parse(defaultUser);

  mounted(){
    this.init()
  }

  async init() {
    this.formUser.accountId = this.userAccountId
    await this.changeService();
  }

  async submit() {
    await this.deleteService()
    
    const response = await AccountStore.createUser(this.formUser);
    if(response){
      this.show = false;
      this.refreshForm()
    }    
  }

  async deleteService(){
    let serviceList = this.$dictionary({key:'services', type: 'list'}) as IService[]

    if (this.formUser.serviceIds){
      serviceList.forEach((service) => {  
        if(!this.formUser.serviceIds.includes(service.id) && this.formUser.rolesRights[service.id]){
          this.$delete(this.formUser.rolesRights, service.id)
        }
      })
    }
  }
  
  async changeService() {
    if (this.formUser.serviceIds) {
      this.formUser.serviceIds.forEach( async (service: number) => {
        const fetchServiceRoles = await RolesStore.fetchServiceRoles({
          serviceId: service,
        });
        const fetchServiceRights = await RolesStore.fetchServiceRights({
          serviceId: service,
        });
        this.$set(this.selects, service, { fetchServiceRoles, fetchServiceRights });

        let roles: any = []
        let rights: any = []
        
        if(!this.formUser.rolesRights[service]){
          this.$set(this.formUser.rolesRights, service, { roles, rights });
        }
      })
    }
  }

  get userAccountId() {
    return AuthStore.userInfo.account.id;
  }

  open() {
    this.init()
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  async handleOpen() {
    return;
  }

  refreshForm() {
    this.formUser = JSON.parse(defaultUser);
  }

}
