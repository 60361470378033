import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IOrganizationsResponse } from "@/store/organization/interfaces";
import { IPaginationParams } from "@/interfaces";

@Module({ dynamic: true, store, name: "Organizations" })
class Organization extends VuexModule {
  public _organizations = {} as IOrganizationsResponse;

  @Mutation
  public SET_ORGANIZATIONS(organizations: IOrganizationsResponse): void {
    this._organizations = organizations;
  }

  @Action({ rawError: true })
  public async getOrganizations(body?: IPaginationParams & {  
      filter?: {
        sub?: number[],
      },
      like?: {
        search: "",
      }
      sort?: {
        name: "ASC" | "DESC"
      }
    }
  ) {
    const response: AxiosResponse<IOrganizationsResponse> =
      await instanceBackend.post("/org/list", body);

    if (response.data) this.context.commit("SET_ORGANIZATIONS", response.data);

    return response.data;
  }

  get organizations(): IOrganizationsResponse {
    return this._organizations;
  }

  public _organizations_ = {} as IOrganizationsResponse;

  @Action({ rawError: true })
  public async getOrganizations_(body?: IPaginationParams & {  
      filter?: {
        sub?: number[],
      },
      like?: {
        search: "",
      }
      sort?: {
        name: string
      }
    }
  ) {
    const response: AxiosResponse<IOrganizationsResponse> =
      await instanceBackend.post("/org/list", body);

    if (response.data) this.SET_ORGANIZATIONS_(response.data);

    return response.data;
  }

  @Mutation
  public SET_ORGANIZATIONS_(organizations: IOrganizationsResponse): void {
    this._organizations_ = organizations;
  }

  get organizations_(): IOrganizationsResponse {
    return this._organizations_;
  }
}

export default getModule(Organization);
