













import { Component, Vue } from "vue-property-decorator";
import Menu from "@/components/Menu.vue";
import Sidebar from "@/components/Sidebar.vue";
import AuthStore from "@/store/auth";
import RootStore from "@/store/root";

@Component({
  components: { Menu, Sidebar },
})
export default class App extends Vue {
  public collapse = true;

  async mounted() {
    if (!RootStore.initState && RootStore.access) {
      await RootStore.initApp();
    }
  }

  get isAuth() {
    return AuthStore.loggedIn;
  }
}
