


















import { Component, Vue } from "vue-property-decorator";
import { saveAs } from "file-saver";
import WorkerStore from "@/store/worker";
import { generateRandomString } from "@/utils";

@Component({
  props: {
    worker: { type: Object },
  },
})
export default class ExportWorkerSettingsModal extends Vue {
  show = false;

  form = {
    passwordType: "",
    password: "",
  };

  passwordTypes = [
    {
      key: "empty",
      title: "Без пароля",
    },
    {
      key: "random",
      title: "Рандомный пароль",
    },
    {
      key: "new",
      title: "Новый пароль",
    },
  ];

  open() {
    this.show = true;
  }

  handleClose() {
    this.refreshForm();
  }

  async handleOpen() {
    return;
  }

  refreshForm() {
    this.form = {
      passwordType: "",
      password: "",
    };
  }

  async submit() {
    const password =
      this.form.passwordType === "empty"
        ? ""
        : this.form.passwordType === "random"
        ? generateRandomString(8)
        : this.form.password;

    const { workerUuid, name, id } = this.$props.worker;

    console.log("this.$props.worker", this.$props.worker);

    const blob = new Blob(
      [
        `
        SERVER_ADDRESS = https://api24.vdcloud.ru\n\n
        SOCKET_RECONNECTION_TIMEOUT = 5000\n\n
        DB_DIALECT = sqlite\n
        DB_STORAGE = worker_db.sqlite\n\n
        WORKER_UUID = ${workerUuid}\n
        WORKER_PASSWORD = ${password}
        `,
      ],
      {
        type: "text/plain;charset=utf-8",
      }
    );

    saveAs(blob, `${name}.settings.env`);

    await WorkerStore.updateWorker({
      id,
      body: {
        password,
      },
    });

    this.refreshForm();
    this.show = false;
  }
}
