import { render, staticRenderFns } from "./UserRightsModal.vue?vue&type=template&id=10d6bcc8&scoped=true&lang=pug&"
import script from "./UserRightsModal.vue?vue&type=script&lang=ts&"
export * from "./UserRightsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d6bcc8",
  null
  
)

export default component.exports