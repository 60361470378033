import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";
import {
  ICashBox,
  ICashboxesList,
  ICashBoxListResponse,
  ICashBoxResponse,
  ICashboxStatus,
} from "@/store/cashbox/interfaces";
import { AxiosResponse } from "axios";

@Module({ dynamic: true, store, name: "Cashboxes" })
class Cashbox extends VuexModule {
  private _cashbox = {} as ICashBox;
  public _cashboxList = {} as ICashboxesList;
  public _workerList = {} ;

  @Mutation
  public SET_CASHBOX_LIST(data: ICashboxesList): void {
    this._cashboxList = data;
  }

  @Mutation
  public SET_CASHBOX(cashbox: ICashBox) {
    this._cashbox = cashbox;
  }

  @Action({ rawError: true })
  public async getCashboxesList(options?: {
    filter: {
      [key: string]: any
    };
    sort?: {
      status: "ASC" | "DESC";
    };
  }) {
    const response: AxiosResponse<ICashBoxListResponse> =
      await instanceBackend.post("/cashbox/list", options);

    this.SET_CASHBOX_LIST(response.data.result);

    return response.data?.result;
  }

  @Action({ rawError: true })
  public async getCashbox(cashboxId: number) {
    const response: AxiosResponse<ICashBoxResponse> = await instanceBackend.get(
      `/cashbox/info/${cashboxId}`
    );

    this.SET_CASHBOX(response?.data.result);
    return response
  }

  @Action({ rawError: true })
  public async getExtendedData(cashboxId: number) {
    const response = await instanceBackend.get(`/cashbox/getExtendedData/${cashboxId}`);

    return response;
  }

  @Action({ rawError: true })
  public async getHardware(cashboxId: number) {
    await instanceBackend.get(`/cashbox/hardware/${cashboxId}`);
  }

  @Action({ rawError: true })
  public async updateCashbox(requestBody: { cashbox: any; id: number }) {
    await instanceBackend.put(
      `/cashbox/${requestBody.id}`,
      requestBody.cashbox
    );
  }

  @Action({ rawError: true })
  public async initWorkerConnection(body: { cashboxes: number[] }) {
    await instanceBackend.post(`/cashbox/initWorkerConnection`, body);
  }

  get cashboxStatuses(): ICashboxStatus[] {
    return [
      {
        id: 0,
        name: "Активна",
      },
      {
        id: 1,
        name: "Нет связи",
      },
      {
        id: 2,
        name: "Заблокирована",
      },
    ];
  }

  get cashbox() {
    return this._cashbox;
  }

  get cashboxList() {
    return this._cashboxList;
  }

}

export default getModule(Cashbox);
