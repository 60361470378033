import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";
import { ISystemEventsList, ISystemEvent } from "./interface";

@Module({ dynamic: true, store, name: "SystemEvent" })
class SystemEvent extends VuexModule {
  public systemEventsList: ISystemEventsList = {} as ISystemEventsList;
  public systemEvent: ISystemEvent = {} as ISystemEvent;

  @Mutation
  public SET_SYSTEM_EVENTS(systemEventsList: ISystemEventsList): void {
    this.systemEventsList = systemEventsList;
  }

  @Action({ rawError: true })
  public async getSystemEvents(body?: {
    limit?: number;
    page?: number;
    sort?: any;
    filter?: any;
    range?: any;
    like?: any;
  }) {
    await instanceBackend.post("/events/list", body).then((response: any) => {
      if (response.data && response.data.result) {
        this.context.commit("SET_SYSTEM_EVENTS", response.data.result);
      }
    });
  }

  //   @Action({ rawError: true })
  //   public async getSystemEvents() {
  //     await instanceBackend.get("/events/list").then(response => {
  //       if (response.data && response.data.result) {
  //         this.context.commit("SET_SYSTEM_EVENTS", response.data.result);
  //       }
  //     });
  //   }
}

export const SystemEventModule = getModule(SystemEvent);
