import { DirectionTable, IPagination } from "@/interfaces";

export interface ILicenseListResponse extends IPagination {
  data: ILicense[];
}

export interface ILicense {
  accountId: number;
  activeFrom: string;
  activeTo: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  licenseId: string;
  name: string;
  service: string;
  status: ELicenseStatus;
  tariff: any
  tariffId: string;
  updatedAt: string;
  licenseServiceInfo?: any;
  balance: {
    accountId: number,
    createdAt: string,
    currentValue: string,
    deletedAt: string,
    id: string,
    updatedAt: string,
  }
}

export interface ILicenseListSort {
  createdAt: DirectionTable;
  name: DirectionTable,
  accountId: DirectionTable,
  isBindedToDevice: DirectionTable,
  activeFrom: DirectionTable,
  activeTo: DirectionTable,
  status: DirectionTable,
  tariffId: DirectionTable,
}

export enum ELicenseStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PAUSED = "paused",
  EXPIRED = "expired"
}

export interface ILicenseStatus {
  id: ELicenseStatus;
  name: string;
  type: string
}
