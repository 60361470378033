













































import { Component, Mixins } from "vue-mixin-decorator";
import { IProduct } from "@/store/product/interfaces";
import TranslateMixin from "@/mixins/TranslateMixin";
import ProductStore from "@/store/product/index";
import CreateProductModal from "@/views/products/modals/CreateProductModal.vue";
import EditProductModal from "@/views/products/modals/EditProductModal.vue";

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10
});

const defaultSorts = JSON.stringify({
  id: "DESC",
});

@Component({
  name: "Products",
  components: { CreateProductModal, EditProductModal }
})

export default class Products extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  
  selectedProduct = {} as IProduct;

  loading = false;

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  mounted() {
    this.init()
  }

  async init() {
    await this.getProductList()
  }

  async getProductList() {
    this.loading = true
    await ProductStore.getProductsList(this.filters);
    this.loading = false
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getProductList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getProductList();
  }

  async handleActions(event: any) {
    this.selectedProduct = event && event.row ? event.row : {};

    if (event.name === "CreateProduct") {
      const modal: any = this.$refs["CreateProductModal"];
      modal.open();
    }

    if (event.name === "EditProduct") {
      const modal: any = this.$refs["EditProductModal"];
      modal.open();
    }

    if (event.name === "RemoveProduct") {
      this.$confirm(
        `Удалить услугу ${event.row.serviceName} - ${event.row.name} и ${event.row.tariffs.length} тарифов?`,
        "Осторожно",
        {
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await ProductStore.removeProduct(this.selectedProduct.id)
        this.getProductList()
      });
    }
  }

  get productList() {
    return ProductStore.productsList;
  }
  
}
