













import { Component, Vue } from "vue-property-decorator";
import ReceiptStore from "@/store/receipt";
import { Notification } from "element-ui";

@Component({
  props: {
    receipt: { type: Object },
  },
})
export default class GetDataByFdnModal extends Vue {
  show = false;

  form = {
    fdn: null as number | null,
  };

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  handleOpen() {
    console.log("open");
  }

  async handle() {
    if (!this.form.fdn) return;

    await ReceiptStore.updateFDByReceiptNumber({
      receiptId: this.$props.receipt.id,
      receiptNumber: this.form.fdn,
    });

    Notification.success({
      title: "Успешно",
      message: "Данные успешно отправлены в очередь",
    });

    this.show = false;
  }
}
