

























































import { Component, Vue, Watch } from "vue-property-decorator";
import AccontMembersStore from "@/store/accontMembers";
import { updateQuery } from "@/utils";
import UserRightsModal from "@/views/roleSettings/modals/UserRightsModal.vue";
import UserEditModal from "@/views/roleSettings/modals/UserEditModal.vue";
@Component({
  props: {
    serviceId: { type: Number, required: true },
  },
  components: { UserRightsModal, UserEditModal },
})
export default class UserRights extends Vue {
  members: any = [];
  loading = false;
  editableUser = null;
  filters = {
    search: (this.$route.query.search as string) || "",
    page: 1,
    limit: 10
  };
  additionalColums = {
    rightKey: false,
  };

  mounted() {
    this.fetchData();
  }

  @Watch("serviceId")
  serviceChange() {
    this.fetchData();
  }

  async changeSearch() {
    updateQuery(this, this.filters);
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    this.members = await AccontMembersStore.getServiceMembers({
      serviceId: this.$props.serviceId,
      filters: this.filters,
    });
    this.loading = false;
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    console.log(this.filters)
    this.fetchData();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.fetchData();
  }

  async handleActions(event: any) {
    if (event.name === "OpenUserRightsModal") {
      this.editableUser = event.row;
      const modal: any = this.$refs["UserRightsModal"];
      modal.open();
    }
    if (event.name === "OpenUserEditModal") {
      this.editableUser = event.row;
      const modal: any = this.$refs["UserEditModal"];
      modal.open();
    }
    // if (event.name === "RemoveRole") {
    //   this.$confirm(
    //     `Если удалить роль без обратной совместимости, то все роуты завязанные на этой роли перестанут работать. Удалить роль ${event.row.key}?`,
    //     "Осторожно",
    //     {
    //       confirmButtonText: "Удалить",
    //       cancelButtonText: "Отмена",
    //       center: true,
    //       confirmButtonClass: "el-button--danger",
    //       type: "error",
    //     }
    //   ).then(async () => {
    //     await RolesStore.removeRole({
    //       serviceId: this.$props.serviceId,
    //       key: event.row.key,
    //     });
    //     this.fetchData();
    //   });
    // }
    // if (event.name === "RoleRights") {
    //   this.selectedType = event.name;
    //   this.selectedRole = event.row;
    //   this.fetchRoleRights();
    // }
    // if (event.name === "RoleDependency") {
    //   this.selectedType = event.name;
    //   this.selectedRole = event.row;
    //   this.fetchRoleDependency();
    // }
    // if (event.name === "EditRole") {
    //   this.editableRow = event.row;
    // }
  }
}
