
















import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";
import AgreementStore from '@/store/agreement/index';
import { Notification } from "element-ui";
import SelectOrganizations from "@/components/SelectOrganizations.vue";

@Component({
  components: { SelectOrganizations,},
  mixins: [TranslateMixin],
})
export default class CreateAgreementModal extends Mixins<TranslateMixin>(TranslateMixin) {
  innerOperationRequired = true
  show = false;
  selectedOrgId = null
  loading = false

  open() {
    this.loading = false
    this.show = true;
  }

  async createAgreement() {
    if(!this.selectedOrgId) {
        Notification({
            title: 'Ошибка',
            message: 'Организация не выбрана',
            type: 'error',
        })
    } else {
      this.loading = true
      AgreementStore.createAgreement({
        orgId: Number(this.selectedOrgId),
        accountId: this.$dictionary({key: 'organization', type: 'map'})?.[this.selectedOrgId as any].sub,
        innerOperationRequired: this.innerOperationRequired,
      });
      this.show = false;
    }
  }
}
