import {
    VuexModule,
    Module,
    Mutation,
    Action,
    getModule,
  } from "vuex-module-decorators";
import { instanceBilling } from "@/plugins/axios";
import store from "@/store";
import {
  ITariff,
  ITariffList
} from "@/store/tariff/interfaces";
import { AxiosResponse } from "axios";
import { IPagination, IPaginationParams } from "@/interfaces";
  
  @Module({ dynamic: true, store, name: "Tariffs" })
  class Tariff extends VuexModule {
    public _tariffsList = {} as ITariffList;
    public _tariff: ITariff = {} as ITariff;
  
    @Mutation
    public SET_TARIFF_LIST(data: ITariffList): void {
      this._tariffsList = data;
    }

    @Mutation
    public SET_TARIFF(data: ITariff): void {
      this._tariff = data;
    }
  
    @Action({ rawError: true })
    public async getTariffsList(filters: IPaginationParams & { productId: string, }
    ) {
      const response: AxiosResponse<ITariffList> =
        await instanceBilling.get(`/tariffs`, { params: filters });

      this.SET_TARIFF_LIST(response.data);
      return response.data;
    }

    @Action({ rawError: true })
    public async getTariff(id: number) {

      const response: AxiosResponse<ITariff> = await instanceBilling.get(
        `/tariffs/${id}`
      );
  
      this.SET_TARIFF(response.data);
  
      return response;
    }

    @Action({ rawError: true })
    public async createTariff(body: ITariff) {
      body.duration = parseInt(body.duration.toString())
      await instanceBilling.post("/tariffs", body);
    }

    @Action({ rawError: true })
    public async removeTariff(id: number) {
      await instanceBilling.delete(`/tariffs/${id}`);
    }

    @Action({ rawError: true })
    public async updateTariff(body: ITariff) {
      body.duration = parseInt(body.duration.toString())
      body.cost = body.cost.toString()
      await instanceBilling.put(`/tariffs/${body.id}`, body);
    }
  
    get tariffsList() {
      return this._tariffsList;
    }

    get tariff() {
      return this._tariff;
    }

  }
  
  export default getModule(Tariff);