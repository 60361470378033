import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import store from "@/store";
import { 
  IReceiptsList,
  IReceiptUuidResponse,
} from "@/store/receipt/interfaces";
import { AxiosResponse } from "axios";

@Module({ dynamic: true, store, name: "Receipts" })
class Receipt extends VuexModule {
  public receipts: any[] = [];
  public receiptList: IReceiptsList = {} as IReceiptsList;

  @Mutation
  public SET_RECEIPTS(receipts: any[]): void {
    this.receipts = receipts;
  }

  @Mutation
  public SET_RECEIPT_LIST(receiptList: IReceiptsList): void {
    this.receiptList = receiptList;
  }

  @Action({ rawError: true })
  public async getReceipts(options: any) {
    const response: any = await instanceBackend.post("/receipt/list", options);
    return response.data?.result;
  }
  @Action({ rawError: true })
  public async resendReceipts(receipt: any) {
    await instanceBackend.put(`/receipt/${receipt.id}`, {
      uuid: receipt.uuid,
    });
  }

  @Action({ rawError: true })
  public async updateFiscalData(receipt: any) {
    await instanceBackend.post(`/receipt/updateFiscalData`, {
      receiptUuid: receipt.uuid,
    });
  }

  @Action({ rawError: true })
  public async updateFDByReceiptNumber(body: {
    receiptId: number;
    receiptNumber: number;
  }) {
    await instanceBackend.post(`/receipt/getFdFromCashbox`, {
      receiptId: body.receiptId,
      receiptNumber: body.receiptNumber,
    });
  }

  @Action({ rawError: true })
  public async getReceiptUuid(id: any) {
    const response: AxiosResponse<IReceiptUuidResponse> =
      await instanceBackend.get(`/receipt/info/${id}`);
    return response.data.result.uuid;
  }

  get receiptStatusesList() {
    return [
      { key: 211, title: "Ожидает оплаты" },
      { key: 201, title: "Ошибка оплаты" },
      { key: 202, title: "Просрочена оплата" },
      { key: 11, title: "Новый" },
      { key: 10, title: "Отправлен" },
      { key: 0, title: "Успех" },
      { key: 1, title: "Ошибка" },
      { key: 100, title: "Ошибка 100" },
      { key: 101, title: "Нет ответа" },
      { key: 111, title: "Ошибка 111" },
      { key: 1001, title: "Ошибка отправки 1001" },
      { key: 1002, title: "Ошибка приема 1002" },
    ];
  }

  get receiptStatusesMap() {
    return this.receiptStatusesList.reduce((acc, status) => {
      if (!acc[status.key]) acc[status.key] = status.title;
      return acc;
    }, {} as { [key: number]: string });
  }

  get receiptTypesList() {
    return [
      { key: 1, title: "Чек прихода" },
      { key: 2, title: "Чек расхода" },
      { key: 3, title: "Чек возврат прихода" },
      { key: 4, title: "Чек возврат расхода" },
      { key: 5, title: "Чек коррекции расхода" },
      { key: 6, title: "Чек коррекции прихода" },
      { key: 7, title: "Чек коррекции возврата расхода" },
      { key: 8, title: "Чек коррекции возврата прихода" },
    ];
  }
}
export default getModule(Receipt);
