












































import { Component, Vue } from "vue-property-decorator";
import RolesStore from "@/store/roles";
import { IRight } from "@/store/roles/interfaces";
import { IService } from "@/store/root/interfaces";

@Component({
  props: {
    role: { type: Object },
    serviceId: { type: Number },
  },
})
export default class RoleRightsModal extends Vue {
  show = false;
  loading = false;
  roleRights: IRight[] | null = null;
  serviceRights: IRight[] | null = null;
  exportedRights: IRight[] = [];
  exportRightForm = {
    serviceId: null,
    rights: [] as string[],
  };
  roleForm = {
    key: "",
    name: "",
    description: "",
  };
  form = {
    serviceId: 1,
    roleKey: "",
    rights: [
      {
        new: true,
        key: "",
        name: "",
        description: "",
        roles: [] as string[],
      },
    ],
  };

  async updateRoleRights() {
    await RolesStore.updateRoleRights(this.form);
  }

  addRow() {
    if (!this.unusedRights.length) return;
    this.form.rights.unshift({
      new: true,
      key: "",
      name: "",
      description: "",
      roles: [],
    });
  }
  deleteRow(row: any) {
    if (this.form.rights.length === 1) {
      this.form.rights = [
        {
          new: true,
          key: "",
          name: "",
          description: "",
          roles: [this.$props.role.key],
        },
      ];
      return;
    }
    this.form.rights = this.form.rights.filter((r) => r.key !== row.key);
  }

  changeKeyRole(role: any) {
    role.key = role.key.toUpperCase();
  }

  open() {
    this.show = true;
  }
  handleClose() {
    console.log("close");
  }
  handleOpen() {
    this.init();
  }

  reset() {
    this.form.rights = [
      {
        new: true,
        key: "",
        name: "",
        description: "",
        roles: [],
      },
    ];
    this.exportRightForm = {
      serviceId: null,
      rights: [] as string[],
    };
  }
  async fetchRoleRights() {
    this.loading = true;

    this.roleRights = await RolesStore.fetchRoleRights({
      role: this.$props.role,
    });

    if (this.roleRights.length) {
      this.form.rights = this.roleRights.map((r: any) => {
        r.roles = [this.$props.role.key];
        return r;
      });
    }

    this.loading = false;
  }

  async fetchServiceRights() {
    this.loading = true;
    this.serviceRights = await RolesStore.fetchServiceRights({
      serviceId: this.$props.serviceId,
    });
    this.loading = false;
  }

  async init() {
    this.form.serviceId = this.$props.serviceId;
    this.form.roleKey = this.$props.role.key;
    this.reset();
    this.fetchRoleRights();
    this.fetchServiceRights();
  }

  getRandomIdRight(rightKey: string) {
    return String(Math.random()) + rightKey;
  }

  get filteredServiceList() {
    const list = this.$dictionary({
      key: "services",
      type: "list",
    }) as IService[];

    return list.filter((s) => s.id !== this.form.serviceId);
  }

  get getUsedRights() {
    return this.form.rights.reduce((acc, r) => {
      if (!acc[r.key]) acc[r.key] = true;
      return acc;
    }, {} as any);
  }

  get unusedRights() {
    if (!this.serviceRights) return [];

    return this.serviceRights.filter((r) => {
      return !this.getUsedRights[r.key];
    });
  }
}
