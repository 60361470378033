















































































































import { Component, Mixins } from "vue-mixin-decorator";
import { Prop } from "vue-property-decorator";
import CashBoxStore from "@/store/cashbox";
import TranslateMixin from "@/mixins/TranslateMixin";
import { ICashBox } from "@/store/cashbox/interfaces";
import { ISnoDictionary } from "@/store/root/interfaces";
import { Notification } from "element-ui";
import SelectOrganizations from "@/components/SelectOrganizations.vue";

const initForm: Partial<ICashBox> = {
  address: "",
  comment: "",
  connectInterfaceId: undefined,
  factoryNumber: "",
  needPrintOnPaper: false,
  ofdId: undefined,
  regNumber: undefined,
  tradePlace: "",
  cashboxModelId: undefined,
  organizationId: undefined,
  fnId: undefined,
  fn: {
    activedTime: '',
    expinTime: '',
    number: '',
  },
  defaultSnoId: undefined,
  ffdId: undefined,
  snoListId: [],
  agentTypesId: []
};

@Component({
  components: { SelectOrganizations },
  mixins: [TranslateMixin],
})
export default class CashBoxModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  @Prop() readonly cashboxId!: number;
  @Prop({ required: true }) readonly mode!: "edit" | "create";
  @Prop({ default: false }) readonly nested!: boolean;

  show = false;

  load = false;

  form = initForm;

  open() {
    this.show = true;
  }

  handleClose() {
    this.show = false;
    this.form = initForm;
  }

  async handleOpen() {
    this.init();
  }

  async init() {
    if (this.mode === "edit") {
      this.load = true;

      const response = await CashBoxStore.getCashbox(this.cashboxId);
      if(!response) {
        this.show = false;
        return
      }

      this.form = { ...this.form, ...this.cashbox };
      this.form.agentTypesId = this.cashbox.agentTypesId?.map(type => Number(type))
      this.load = false;
    }
  }

  async handle() {
    await CashBoxStore.updateCashbox({
      id: this.cashbox.id,
      cashbox: this.form,
    });
    this.show = false;
    this.$emit("submit");
  }

  get cashbox() {
    return CashBoxStore.cashbox;
  }

  get snoFilteredList() {
    const list = this.$dictionary({key: 'sno', type: 'list'})
    if (this.form.snoListId && !this.form.snoListId.length)
      return list;
    return (list as ISnoDictionary[]).filter((sno) =>
      this.form.snoListId?.includes(sno.id)
    );
  }

  changeActivedTime() {
    if(this.form.fn && this.form.fn.activedTime && this.form.fn.expinTime
      && new Date(this.form.fn.activedTime).getTime() > new Date(this.form.fn.expinTime).getTime()) {
        this.form.fn.activedTime = ''
        Notification.error({
          title: "Ошибка",
          message: "Дата активации не может быть позднее даты окончания",
        });
      }
  }

  changeExpinTime() {
    if(this.form.fn && this.form.fn.activedTime && this.form.fn.expinTime
      && new Date(this.form.fn.expinTime).getTime() < new Date(this.form.fn.activedTime).getTime()) {
        this.form.fn.expinTime = ''
        Notification.error({
          title: "Ошибка",
          message: "Дата окончания не может быть раньше даты активации",
        });
      }
  }
}
