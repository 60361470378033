export const availableBidStatuses = [
  { name: "Отказана", code: 1 },
  { name: "Исполнена", code: 0 },
];

export const allBidStatuses = [
  { name: "Новая", code: 11 },
  { name: "Принята", code: 10 },
  { name: "Ожидает оплаты", code: 9 },
  { name: "Обрабатывается", code: 8 },
  { name: "Отказана", code: 1 },
  { name: "Исполнена", code: 0 },
];

export const allFeedbackStatuses = [
  { name: "Новая", code: 11 },
  { name: "Отклонена", code: 1 },
  { name: "Исполнена", code: 0 },
];

export const allReceiptStatuses = [
  { name: "Новый", code: 11 },
  { name: "Отправлен", code: 10 },
  { name: "Успех", code: 0 },
  { name: "Ошибка", code: 1 },
  { name: "Ожидает оплаты", code: 211 },
  { name: "Ошибка оплаты", code: 201 },
  { name: "Просрочена оплата", code: 202 },
  { name: "Ошибка 100", code: 100 },
  { name: "Нет ответа", code: 101 },
  { name: "Ошибка 111", code: 111 },
  { name: "Ошибка отправки 1001", code: 1001 },
  { name: "Ошибка приема 1002", code: 1002 },
];

export const allReceiptTypes = [
  { name: "Чек прихода", code: 1 },
  { name: "Чек расхода", code: 2 },
  { name: "Чек возврат прихода", code: 3 },
  { name: "Чек возврат расхода", code: 4 },
  { name: "Чек коррекции расхода", code: 5 },
  { name: "Чек коррекции прихода", code: 6 },
];

export const allOwnerTypes = [
  { name: "Касса", code: 0 },
  { name: "Пользователь", code: 1 },
  { name: "Чек", code: 2 },
  { name: "Воркер", code: 3 },
];
