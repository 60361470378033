






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Input extends Vue {
  @Prop({ default: '' }) value!: any;
  @Prop() id!: string;
  @Prop() placeholder!: string | number;
}
