






































import { Component, Mixins } from "vue-mixin-decorator";
import { Prop } from "vue-property-decorator";
import QrStore from "@/store/qr";
import TranslateMixin from "@/mixins/TranslateMixin";
import { IQrLicense } from "@/store/qr/interfaces";

@Component({
  mixins: [TranslateMixin],
})
export default class BidCashBoxesModal extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  @Prop({ required: true }) readonly licenseId!: number;

  show = false;

  load = false;

  form = {
    name: "",
    activeTo: "",
    secret: "",
    comment: "",
  } as IQrLicense;

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  async init() {
    this.load = true;
    await QrStore.getQrLicence(this.licenseId);
    this.form = { ...this.form, ...this.qrLicense };
    this.load = false;
  }

  async handle() {
    await QrStore.updateQrLicence({
      licenseId: this.licenseId,
      form: this.form,
    });
    this.$emit("reload");
  }

  async handleOpen() {
    await this.init();
  }

  get qrLicense() {
    return QrStore.qrLicence;
  }
}
