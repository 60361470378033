import Vue from "vue";
import moment from "moment";

Vue.filter(
  "formatDate",
  function (date: string, format = "DD-MM-YYYY HH:mm:ss") {
    if (!date) return "-";
    return moment(date).format(format);
  },
);

