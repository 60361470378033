


















import { Component, Mixins } from "vue-mixin-decorator";
import { Prop } from "vue-property-decorator";
import BidStore from "@/store/bid";
import TranslateMixin from "@/mixins/TranslateMixin";
import CashBoxModal from "@/components/modals/CashBoxModal.vue";
import { ICashBox } from "@/store/cashbox/interfaces";

@Component({
  components: { CashBoxModal },
  mixins: [TranslateMixin],
})
export default class BidModal extends Mixins<TranslateMixin>(TranslateMixin) {
  @Prop({ required: true }) readonly bidId!: number;

  selecedCashBox = {} as ICashBox;

  show = false;

  load = false;

  form = {
    status: null,
  };

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  async handleOpen() {
    await this.init();
  }

  async handle() {
    await BidStore.updateBid({ id: this.bid.id, bid: this.form });
    this.show = false;
    this.$emit("reload");
  }

  async init() {
    this.load = true;
    await BidStore.getBid(this.bidId);
    this.load = false;
  }

  get bidStatuses() {
    return BidStore.bidStatuses;
  }

  get bid() {
    return BidStore.bid;
  }
}
