




















































































import { Component, Vue } from "vue-property-decorator";
import RolesStore from "@/store/roles";
import { IRight, IRole } from "@/store/roles/interfaces";
import { IService } from "@/store/root/interfaces";

@Component({
  props: {
    right: { type: Object },
    serviceId: { type: Number },
  },
})
export default class RolesModal extends Vue {
  show = false;
  loading = false;
  showRoleForm = false;
  showExportRoleForm = false;
  rightsList: IRight[] | null = null;
  exportedRoles: IRole[] = [];
  exportRoleForm = {
    serviceId: null,
    roles: [] as string[],
  };
  rightForm: Partial<IRight> = {
    key: "",
    name: "",
    description: "",
  };
  form = {
    serviceId: 1,
    roles: [
      {
        id: Date.now(),
        key: "",
        name: "",
        description: "",
        rights: [],
      },
    ],
  };

  async batchCreateRole() {
    await RolesStore.batchCreateRoles(this.form);
  }

  async createRight() {
    await RolesStore.batchCreateRights({
      serviceId: this.form.serviceId,
      rights: [this.rightForm],
    });

    this.fetchServiceRights();
  }

  exportRoles() {
    console.log("this.exportRoleForm.", this.exportRoleForm);
    this.exportRoleForm.roles.map((r) => {
      const foundRight = this.exportedRoles.find((exportRight) => {
        return exportRight.key === r;
      });
      if (foundRight) {
        const obj = { ...foundRight, id: Math.random(), roles: [] } as any;
        const foundExistingRight = this.form.roles.find(
          (formRole) => formRole.key === foundRight.key
        );
        if (!foundExistingRight) {
          this.form.roles.push(obj);
        }
      }
      this.filterEmptyRoles();
    });
  }

  async changeExportService() {
    if (!this.exportRoleForm.serviceId) return;

    this.exportRoleForm.roles = [];

    this.exportedRoles = await RolesStore.fetchServiceRoles({
      serviceId: Number(this.exportRoleForm.serviceId),
    });
  }

  filterEmptyRoles() {
    this.form.roles = this.form.roles.filter((r) => !!r.key);
  }

  handleShowRightForm() {
    this.showExportRoleForm = false;
    this.showRoleForm = !this.showRoleForm;
  }

  handleShowexportRoleForm() {
    this.showRoleForm = false;
    this.showExportRoleForm = !this.showExportRoleForm;
  }

  addRow() {
    this.form.roles.push({
      id: Date.now(),
      key: "",
      name: "",
      description: "",
      rights: [],
    });
  }

  deleteRow(row: any) {
    if (this.form.roles.length === 1) return;
    this.form.roles = this.form.roles.filter((r) => r.id !== row.id);
  }
  changeKeyRight(right: any) {
    right.key = right.key.toUpperCase();
  }

  changeKeyRole(role: any) {
    role.key = role.key.toUpperCase();
  }

  open() {
    this.show = true;
  }

  handleClose() {
    console.log("close");
  }

  handleOpen() {
    console.log("open");
    this.init();
  }

  changeService() {
    this.init();
  }

  reset() {
    this.form.roles = [
      {
        id: Math.random(),
        key: "",
        name: "",
        description: "",
        rights: [],
      },
    ];
    this.exportRoleForm = {
      serviceId: null,
      roles: [] as string[],
    };
  }
  async fetchServiceRights() {
    this.loading = true;
    this.rightsList = await RolesStore.fetchServiceRights({
      serviceId: this.form.serviceId,
    });
    this.loading = false;
  }

  async init() {
    this.form.serviceId = this.$props.serviceId;
    this.reset();
    this.fetchServiceRights();
  }

  get filteredServiceList() {
    const list = this.$dictionary({
      key: "services",
      type: "list",
    }) as IService[];

    return list.filter((s) => s.id !== this.form.serviceId);
  }
}
